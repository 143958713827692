import React from 'react';
import InterviewQuestion from '../../../component/interview-questions/InterviewQuestion';
import {questions, summary, introduction} from '../../../component/interview-questions/JsThisQuestions';

const InterviewQuestionsJsThis = () => {
    return (<InterviewQuestion
        title="JavaScript &quot;This&quot; Interview Questions"
        introduction={introduction}
        summary={summary}
        questions={questions} 
        featureImage="https://canosielabs.com/img/articles/js/js-this-interview.png"
        />);
};

export default InterviewQuestionsJsThis;