import React from 'react';
import Banner from '../banner/Banner';
import './MainLayout.scss';
import NavBar from "../nav-bar/NavBar";

const MainLayout = ({children}) => {
  return (
    <div className="container-fluid">
        <NavBar />
      {children}
    </div>
  );
};

MainLayout.propTypes = {
};

export default MainLayout;
