import React from 'react';
import LeetQuestion from './LeetQuestion';
import { MathJax } from "better-react-mathjax";
import TransitionFunction from './TransitionFunction';

const Knapsack = () => {
    const qd = (
        () => {
            return (<>
                Given weights and values of n items, put these items in a knapsack of capacity W to get the maximum total value in the knapsack.
                <br />In other words, given two integer arrays val[0..n-1] and wt[0..n-1] which represent values and weights associated with n items respectively.
                <br /> Also given an integer W which represents knapsack capacity, find out the maximum value subset of val[] such that sum of the weights of this
                subset is smaller than or equal to W. You cannot break an item, either pick the complete item or don't pick it (0-1 property).
            </>)
        }
    );

    const examples = [{
        inputs: "value = [60, 100, 120], weight = [10, 20, 30], w = 50",
        outputs: "220",
        explaination: "100 + 120 has a total value of 220 while equal to or less than the max weight of 50."
    }];

    const BruteForce = (
        <>
            In our brute force solution, for each item, we choose wether if we put it in our bag or not.  If we choose item i, then our capcity drops by wt[i]
            but our value increases by val[i].
            <ol>
                <li>The value of our bag either stays the same or increases by val[i].</li>
                <li>The current capacity of our bag stays the same or decreases by wt[i].</li>
            </ol>
            This generaites all combinations of the input items with their associated values.  From there, we can simply select the highest selections with the
            highest value.
            <br />
            At each level in our decision tree, i, we're making two decisions, each of them, will span off two more decisions,
            for n items.  This leads to a runtime of <MathJax inline={true}>{"$O(n^2)$"}</MathJax>.
        </>
    )

    const OverlappingSubproblems = (
        <>Yes.  There will be multiple calls to calculate the best selection of sub-items given a particular sub-weight in our brute force selection.</>
    );


    const Intuition = (
        <>
            <p>
                From our brute force solution, we can see that the overlapping solutions of the current index and capacity are being re-caculated.  What is actually being
                calculated is the maximum value that can be obtained when considering elements of <MathJax inline={true}>{String.raw`$1..i$`}</MathJax> with a max capacity of c.
            </p>
            <p>
                This is a sub-problem of the original problem.  If we cache this result along with the second variable changing, the weight, we can pontentially re-use it in the future without re-calculating this sub-problem again.
            </p>
            <p>
                If we have the solution for the first <MathJax inline={true}>{"$i^{th}$"}</MathJax> elements, then when considering the <MathJax inline={true}>{"$i+1^{th}$"}</MathJax>
                element, we need to consider two situations.
            </p>

            <ol>
                <li>If we decide to take the <MathJax inline={true}>{"$i+1^{th}$"}</MathJax>, then our max value is the value of val[i] plus the max value obtainable with capacity c - wt[i]</li>
                <li>If we not decide to take the <MathJax inline={true}>{"$i+1^{th}$"}</MathJax>, then our max value max value obtainable with capacity c</li>
            </ol>
            <p>
                And from these statements we can obtain our transition function.
            </p>
        </>
    )

    const transitionFunction = <TransitionFunction statement={String.raw`$$f(n,c)=max\begin{cases}f(n-1,c)& do \mspace{5mu}  not \mspace{5mu}  take \mspace{5mu}  n\\f(n-1, c-wt[n]&take \mspace{5mu}  item \mspace{5mu}  n\end{cases}$$`} />
    return <LeetQuestion
        questionDefinition={qd}
        title="0/1 Knapsack"
        examples={examples}
        transitionFunction={transitionFunction}
        bruteForce={BruteForce}
        overlappingSubproblems={OverlappingSubproblems}
        intuition={Intuition}
    />
};

export default Knapsack;