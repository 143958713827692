import React from "react"
import PrismCode from './PrismCode';
const summary = "";
const introduction = "Over the years, I’ve created, been asked, and acquired a number of interview questions." + 
"So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" + 
"my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: <span>What is Spring's application context?</span>,
        answer: (<span>This is sometimes refered to as the IoC (Inversion of Control) container. Loads configuration files (if necessary) Contains access to beans, factory methods,
        configurations, ability to load configurations, etc</span>)
    },
    {
        question: <span>Why does this code print out the numbers in different order each time it is run? 
        <PrismCode code={'List<Integer> arr = new ArrayList<>(List.of(1, 2, 3, 4, 5, 6));  '  +'\n' + 
                '  arr.parallelStream().forEach(c -> {  '  + '\n' +
                '  System.out.println(c);  '  + '\n' +
                '})  '  + '\n' +'\n' 
        } language="java" />
        </span>,
        answer: (<span>Because we are forcing the stream to be executed in parallel, the stream is partitioned into multiple substreams.  The order we see the elements are not guaranteed.
        </span>)
    },
    {
        question: <span>When this code is executed, why is the stream not executed?  Ie, we do not see the System print outs.
        <PrismCode code={'public void runMethod() {'  +'\n' + 
                '  List<Integer> arr = new ArrayList<>(List.of(1, 2, 3, 4, 5, 6));'  +'\n' +
                '  Stream<Integer> s = arr.stream().filter(i -> {'  + '\n' +
                '    System.out.println("Printing element i " + i);'  + '\n' +
                '    return i % 2 == 0;'  + '\n' +
                '  });  '  + '\n'+
                '}  '  + '\n' +'\n'
        } language="java" />
        </span>,
        answer: (<span>This is because of the lazy execution of the stream.   <br/>
        All iteration and function evaluation begins only after invoking the terminal method. <br/>
        Ie if we were to execute <b>s.count()</b> at the end, the System print outs will be displayed.
        </span>)
    },    
];

export {questions, summary, introduction};