import React from "react"
import PrismCode from './PrismCode';
import This from '../code/This';
import Code from '../code/Code';

const summary = "";
const introduction = "Over the years, I’ve created, been asked, and acquired a number of interview questions." +
    "So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" +
    "my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: <span>Can you explain the concept of JavaScript's execution context? What is it?</span>,
        answer: (<span><ul>
            <li>A abstract concept about the "environment" in which the current code is executed in.</li>
            <li>This includes things such as the scope chain, function arguments and also the value of &quot;this&quot;.</li>
            <li>The JavaScript runtime maintains a stack of these execution contexts, and the execution
               context present at the top of this stack is the one currently being executed. </li>
            <li>The object &quot;this&quot; changes every time the execution context is changed.</li>
            <li>There are three types of execution contexts. i) Global, functional, and eval.</li>
        </ul>
        </span>)
    },
    {
        question: <span>Can you explain the difference between the three types of execution contexts?</span>,
        answer: (<span><ul>
            <li>Global Execution Context - The default execution context (base execution context).  Any code not in a function is executed in the global context.</li>
            <li>Functional Execution Context - A new functional execution context is created each time a new function is invoked.</li>
            <li>Eval Execition Context - The in a eval function.</li>
        </ul>
        </span>)
    },
    {
        question: <span>What is the output from the three statements?
            <PrismCode code={ 'const person = {\n'  + 
        '  firstName : \'joe\',\n'  + 
        '  getName: function() {\n'  + 
        '    return this.name;\n'  + 
        '  }\n' + 
        '};\n' +
        '\n' +
        '\n' +
        '// 1 \n' +
        'person.getName(); \n' + '\n' +
        '// 2 \n' +
        'console.log(this);' + '\n' + '\n' +
        '// 3 \n' +
        '"use strict"' + '\n' +
        'console.log(this);'
        } language="javascript" />
        </span>,
        answer: (<span> <ol>
            <li>The output is "joe" since the Functional execution context has <This/> set to the object <Code>person</Code>.</li>
            <li>The output is 'window' since <This/> is assigned to the global object. 
            This is because line of code is being executed in the global execution context.</li>
            <li>Though this code is also being executed in the global execution context, since the use strict directive is being enforced, <This/> is undefined. </li>
        </ol>
        </span>)
    },
    {
        question: <span>What is the output from the statement?
            <PrismCode code={  'function whatIsThis() {'  + '\n' +
                '  console.log(this===window);' + '\n' +
                '}  '  + '\n' + '\n' +
                'whatIsThis();  ' 
        } language="javascript" />
        </span>,
        answer: (<span><Code>true</Code>, since the print statement is not being executed in the context of an object - <This/> is set to window.
        </span>)
    },
    {
        question: <span>What is the output from the statement?
            <PrismCode code={  '"use strict"'+  '\n' +
                'function whatIsThis() {'  + '\n' +
                '  console.log(this===window);' + '\n' +
                '}  '  + '\n' + '\n' +
                'whatIsThis();  ' 
        } language="javascript" />
        </span>,
        answer: (<span><Code>false</Code>, the <Code>use strict</Code> directive is being enforced so <This/> in the 
        functional execution context is undefined.  This is because the function is being invoked in the global context and not as a method on a object.
        </span>)
    },
    {
        question: <span>What is the output from the statement?
            <PrismCode code={  '"use strict"'+  '\n' +
                'function whatIsThis() {'  + '\n' +
                '  console.log(this);' + '\n' +
                '}  '  + '\n' + '\n' +
                'whatIsThis();  ' 
        } language="javascript" />
        </span>,
        answer: (<span><Code>undefined</Code>, the <Code>use strict</Code> directive is being enforced so <This/> in the 
        functional execution context is undefined.  This is because the function is being invoked in the global context and not as a method on a object.
        </span>)
    },
    {
        question: <span>What is the output from the statement?
            <PrismCode code={ 'const person = {  '+ '\n' +
                '  firstName: \'Joe\',  '  + '\n' +
                '  lastName: \'Smith\',  ' + '\n' +
                '   getFirstName: function () {  '  + '\n' +
                '    return this.firstName;  '  + '\n' +
                '  }  '  + '\n' +
                '}  ' + '\n' +
                'console.log(person.getFirstName());  '
        } language="javascript" />
        </span>,
        answer: (<span><Code>Joe</Code>, since the method is being invoked by an object reference.</span>)
    },
    {
        question: <span>What is the output from the two method calls to printLstName?
            <PrismCode code={'"use strict"  '   + '\n' + 
                'const person = {  '   + '\n' +
                '  firstName: \'Joe\',  '   + '\n' + 
                '  lastName: \'Smith\',  '   + '\n' +
                '  printFirstName: function () {  '   + '\n' +
                '   console.log(this.firstName);         '  + '\n' +
                ' }  '  + '\n' +
                '};  '   + '\n' + '\n' +  '\n' +
                'const printLastName = function() { console.log(this.lastName); };  '   + '\n' +
                'person.printLastName = printLastName;  '  + '\n' +
                'person.printLastName();  // 1'   + '\n' +
                'printLastName();  // 2' 
        } language="javascript" />
        </span>,
        answer: (<span>
            <ol>
                <li>This will print <Code>Smith</Code> even though the printLastName function was defined in the global context execution.  When
                it is executed, it will be executed via a object refence so <This/> will refence the <Code>person</Code> object.</li>
                <li>This will output an error.  <Code>TypeError: Cannot read property 'lastName' of undefined</Code>
                This is because the function <Code>getLastName</Code> is invoked, the value of <This/> is set to <Code>undefined</Code>  
                since use strict is being enforced.</li> 
            </ol>
        </span>)
    },
    {
        question: <span>What is the output from the following?
            <PrismCode code={'"use strict"  '  + '\n' +
                'const person = {  '  + '\n' +
                '  firstName: \'Joe\',  '  + '\n' +
                '  lastName: \'Smith\',  '  + '\n' +
                '  printFirstName: function () {  '  + '\n' +
                '    console.log(this.firstName);'  + '\n' +
                '  }  '  + '\n' +
                '};  '  + '\n' +
                '\n' +
                'const printLastName = function() { console.log(this.lastName); };  '  + '\n' +
                'person.printLastName = printLastName;  '  + '\n' +
                'person.printLastName(); '
        } language="javascript" />
        </span>,
        answer: (<span>Even though the function is defined in the global context, 
            it is still invoked by an object reference.  In the function execution context, <This/> is defined to the person object.
        </span>)
    },
    {
        question: <span>What is the output from the following?
            <PrismCode code={'"use strict"  '  + '\n' +
            'function Person(firstName) {  '  + '\n' +
            '  this.firstName = firstName;  '  + '\n' +
            '  this.getFirstName = function() {  '  + '\n' +
            '    return this.firstName;  '  + '\n' +
            '  }  '  + '\n' +
            '}  '  + '\n' +
            '\n' +
            'const person = new Person(\'Jane\');  '  + '\n' +
            'console.log(person.getFirstName());  '
        } language="javascript" />
        </span>,
        answer: (<span>
            After creating a new instance of a person object using the constructor.  
            Since the new keyword is being used, the <This/> referenced in the constructor function references the new object being instantiated.  
            In addition, we are invoking the getFirstName function using a reference to the newly created instance.  
            As a result, the function execution context will have <This/> assigned to this instance.
        </span>)
    },
    {
        question: <span>What is the output from the following?
            <PrismCode code={'"use strict"  '  + '\n' +
                            'function Person(firstName, lastName) {  '  + '\n' +
                            '  this.firstName = firstName;  '  + '\n' +
                            '  this.lastName = lastName;  '  + '\n' +
                            '  this.getFirstName = function() {  '  + '\n' +
                            '    return this.firstName;  '  + '\n' +
                            '  }  '  + '\n' +
                            '}  '  + '\n' +
                            '\n' +
                            'const person = new Person("Jane", "Smith");  '  + '\n' +
                            'Person.prototype.getLastName = function () {  '  + '\n' +
                            '  return this.lastName;  '  + '\n' +
                            '}  '  + '\n' +
                            'console.log(person.getLastName());   '
        } language="javascript" />
        </span>,
        answer: (<span>
            Even though the function is created in the global context, it is invoked using an object reference.  
            Therefore, <This/> references the person object whose lastname property is 'Smith'
        </span>)
    },
    {
        question: <span>What is the output from the following?
            <PrismCode code={'"use strict"  '  + '\n' +
                'function Person(firstName) {  '  + '\n' +
                '  this.firstName = firstName;  '  + '\n' +
                '  this.getFirstName = () => {  '  + '\n' +
                '    return this.firstName;  '  + '\n' +
                '  };  '  + '\n' +
                '}  '  + '\n' +'\n' +
                'const person = new Person(\'Jane\');  '  +'\n' +
                'console.log(person.getFirstName());  '  
        } language="javascript" />
        </span>,
        answer: (<span>
            The <Code>getFirstName</Code> function is invoked using an object reference.
            Therefore, <This/> references the person object whose firstName property is 'Jane'
        </span>)
    },
    {
        question: <span>What is the output from the following?
            <PrismCode code={'"use strict"  '  + '\n' +
                'function Person(firstName) {  '  + '\n' +
                '  this.firstName = firstName;  '  + '\n' +
                '}  '  + '\n' +
                '\n' +
                'const person = new Person(\'Jane\');  '  + '\n' +
                'Person.prototype.getFirstName = () => {  '  + '\n' +
                '    return this.firstName;  '  + '\n' +
                '}  ' +'\n' +'\n' +
                'console.log(person.getFirstName());  '  
        } language="javascript" />
        </span>,
        answer: (<span>
            Arrow functions do not create its own execution context when invoked. 
            The value of <This/>  is inherited from the context where the arrow function is defined.
            Thus, <This/> has the value of <Code>undefined</Code> in the global execution context.
        </span>)
    },
    {
        question: <span>What is the output from the following?
            <PrismCode code={'const person = {  '  + '\n' +
                '  firstName: \'Joe\',  '  + '\n' +
                '  getFirstName : function () {  '  + '\n' +
                '  	return this.firstName;  '  + '\n' +
                '  }  '  + '\n' +
                '}  '  + '\n' +
                '\n' +'\n' +
                'const func = person.getFirstName;  '  + '\n' +
                'console.log(func());  '
        } language="javascript" />
        </span>,
        answer: (<span>
            The output is <Code>TypeError: Cannot read property ' firstName ' of undefined</Code>.<p>
            Functions are first class members thus can be assigned to variables and passed around.
              When <Code>getFirstName</Code> is assigned to a variable and invoked, the execution context is
               different than when it was invoked using a referenced to the person object. 
                Now when the function is invoked, the execution context is the global context.
            </p>
        </span>)
    },
    {
        question: <span>In the code for the previous question, 
            what can be added on line just before the <Code>func()</Code>so that this function's intention
            of returning the person's first name will work as exepcted.          
        </span>,
        answer: (<span>We can bind <This/> explictly to the <Code>person</Code> object by using the <Code>bind</Code> function.
            to person.
            <PrismCode code={
                'person.getFirstName.bind(person)'
        } language="javascript" />
        </span>)
    },
    {
        question: <span>What is the output from the following?
            <PrismCode code={'"use strict"  '  + '\n' +
                    'function Person(firstName) {  '  + '\n' +
                    '  this.firstName = firstName;  '  + '\n' +
                    '  this.getFirstName = () => {  '  + '\n' +
                    '    return this.firstName;  '  + '\n' +
                    '  }  '  + '\n' +
                    '}  '  + '\n' +'\n' +
                    'const person = new Person(\'Jane\');  '  + '\n' +
                    'const func = person.getFirstName;  '  + '\n' +
                    'console.log(func());  ' + '\n' 
        } language="javascript" />
        </span>,
        answer: (<span>It will print out <Code>Jane</Code><p>
            When using arrow functions, the value of <This/> is based on the context when the function was <b>defined</b>, not executed.  
            In this case, <This/> references the person object when the function was defined in the constructor.   
            When invoked, the function’s reference to <This/> does not change when it is executed because a new function execution context is not created.
            </p>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'"use strict"  '  +  '\n' +
                    'const person2 = {  '  +  '\n' +
                    '  firstName: "Jane",  '  +  '\n' +
                    '  age: 19,  '  +  '\n' +
                    '  printAge: () => console.log(this)  '  +  '\n' +
                    '};  '  +  '\n' + '\n' +
                    'person2.printAge(); // what is the output and why?  '

        } language="javascript" />
        </span>,
        answer: (<span><p>The output is <Code>undefined</Code>.</p><p>
            Because for an arrow function, <This/> is not bound at execution time.  
            Rather, the value of <This/> is inherited from the context in which the function is defined.  
            In this case, this is undefined ( or would be window if use strict was not used)            
            </p>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'"use strict"  '  + '\n' +
                    'const person = {  '  + '\n' +
                    '  firstName: \'Joe\',  '  + '\n' +
                    '  printFirstName: function () {  '  + '\n' +
                    '    console.log(this.firstName);  '  + '\n' +
                    '  }  '  + '\n' +
                    '};  '  + '\n' +'\n' +
                    'setTimeout(person.printFirstName, 1000);  '  + '\n' +'\n' +
                    'setTimeout(function() {  '  + '\n' +
                    '  person.printFirstName();  '  + '\n' +
                    '}, 1000);  '
        } language="javascript" />
        </span>,
        answer: (<span><ol>
            <li><Code>undefined</Code>.  This is because we pass a function reference as the method to be executed by <Code>setTimeout</Code>.  The context when
            this method is executed is not within the <Code>person</Code> object, thus, <This/> is set to undefined in the functional exeuction context. </li>
            <li> <Code>joe</Code>This is different than the previous statement becuase we are passing a function, when executed, will execute 
            the <Code>printFirstName</Code> function using an object reference.  Thus, in the functional execution context, <This/> is assigned to the person object.</li>
            </ol>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'"use strict"  '  + '\n' +
                'function Person(firstName, lastName, age) {  '  + '\n' +
                '  this.firstName = firstName;  '  + '\n' +
                '  this.lastName = lastName;  '  + '\n' +
                '  this.age = age;  '  + '\n' +'\n' +
                '  const printNameFunc = function() {  '  + '\n' +
                '  	 console.log(this.firstName + " " + this.lastName);  '  + '\n' +
                '  };  '  + '\n' +'\n' +
                '  this.printName = printNameFunc.bind(this);  '  + '\n' +
                '  this.printAge = () => {  '  + '\n' +
                '  	 console.log(this.age);  '  + '\n' +
                '  }      '  + '\n' +
                '}  '  + '\n' +'\n' +
                'const person = new Person(\'Joe\', \'Smith\', 30);  '  + '\n' +'\n' +
                'person.printName();  // 1'  + '\n' +
                'setTimeout(person.printName, 1000);  // 2'  + '\n' +
                'setTimeout(person.printAge, 1000);  // 3'
        } language="javascript" />
        </span>,
        answer: (<span><ol>
            <li>The first call to <Code>printName()</Code> will output <Code>Joe Smith</Code> because this function 
            is invoked on the person object.  There is a new function execution context created and <This/> is references the person object</li>
            <li>During the creation of the Person instance, we see that <This/> has been bound to the person instance.
            Therefore, even  when <Code>printName()</Code> is invoked without a person reference,  <This/> still references person.</li>
            <li><Code>printAge()</Code> was defined using the arrow notation.  
            As a result,  <This/> is bound by the context in which the function was created.  In this case, this refers to the person object.</li>
            </ol>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'var person = {  '  + '\n' +
            '  firstName: "Joe",  '  + '\n' +
            '  someFunFunc: function() {  '  + '\n' +
            '    var self = this;  '  + '\n' +
            '    console.log(this.firstName);  // 1'  + '\n' +
            '    console.log(self.firstName);  // 2'  + '\n' +
            '    (function() {  '  + '\n' +
            '      console.log(this.firstName);  // 3'  + '\n' +
            '      console.log(self.firstName);  // 4'  + '\n' +
            '    }());  '  + '\n' +
            '  }  '  + '\n' +
            '};  '  + '\n' +
            'person.someFunFunc();  ' 
        } language="javascript" />
        </span>,
        answer: (<span><ol>
            <li><Code>Joe</Code> because the function is being called using an object reference.</li>
            <li><Code>Joe</Code> because the function is being called using an object reference.</li>
            <li><Code>undefined</Code> because the self-invoking function is being <b>not</b> called using an object reference.</li>
            <li><Code>Joe</Code> Even though the socpe of the code in the self-invoking function is not the <Code>Person</Code> object, the <Code>self</Code>
            variable created in the parent scope of the closure still is the person object.</li>
            </ol>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'function Person(firstName, age) {  '  + '\n' +
                            '  this.firstName = firstName;  '  + '\n' +
                            '  this.age = age;  '  + '\n' +'\n' +
                            '  this.printFirstName = () => {  '  + '\n' +
                            '    console.log(this.firstName);  '  + '\n' +
                            '  };  '  + '\n' +
                            '}  '  + '\n' +'\n' +
                            'this.window.firstName = "George";  '  + '\n' +
                            'const printFirstName = function() {  '  + '\n' +
                            '  console.log(this.firstName);  '  + '\n' +
                            '}  '  + '\n' +'\n' +
                            'const person1 = new Person("Joe", 33);  '  + '\n' +'\n' +
                            'const person2 = {  '  + '\n' +
                            '  firstName: "Jane",  '  + '\n' +
                            '  age: 19,  '  + '\n' +
                            '  printAge: () => console.log(this.age)  '  + '\n' +
                            '};  '  + '\n' +'\n' +
                            'person2.printFirstName = person1.printFirstName;  '  + '\n' +'\n' +'\n' +'\n' +
                            'person1.printFirstName();  //1: what is the output and why?  '  + '\n' +
                            'printFirstName();   //2: what is the output and why?  '  + '\n' +
                            'person2.printFirstName();   //3: what is the output and why?  '  + '\n' +'\n' +
                            'person2.printFirstName = printFirstName;  '  + '\n' +
                            'person2.printFirstName();    //4: what is the output and why?  '  + '\n' +
                            'person2.printAge();  //5: what is the output and why?  '
        } language="javascript" />
        </span>,
        answer: (<span><ol>
            <li><Code>Joe</Code> because the function is being called using an object reference.</li>
            <li><Code>George</Code> because when the function executes, it is in the global context, so <This/> references <Code>window</Code> which has a <Code>firstname</Code>
            property set to Geroge.</li>
            <li><Code>Joe</Code> because an the arrow syntax is used to create the function.  The value of <This/> is not bound during execution is has the same valueas it did when
            the function was created  In this case, it refers to the <Code>Person</Code> instance being created with the name <Code>Joe</Code> .</li>
            <li><Code>Jane</Code> The <Code>printFirstName</Code> function being assigned was created with this set to the default window.  However, it was not created using 
            the arrow syntax and invoked using a object reference.  When excuting, <This/> is set to <Code>person2</Code> whos <Code>firstName</Code> is <Code>Jane</Code>.</li>
            <li><Code>undefined</Code>.  The <Code>printAge</Code> function was created using the arrow function syntax thus <Code>This</Code>
             has the default value of <Code>window</Code> since this function is being created in the global execution context.  Even if it is executed using an object reference, since it is a arrow function, the value of <This/> is not set to the value 
             of <This/> in the function execution context.</li>
            </ol>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'"use strict"  '  + '\n' +
                            '\n' +
                            'const person1 = {  '  + '\n' +
                            '  firstName: "Jane",  '  + '\n' +
                            '  age: 19  '  + '\n' +
                            '};  '  + '\n' +
                            '\n' +
                            'const person2 = {  '  + '\n' +
                            '  firstName: "Joe",  '  + '\n' +
                            '  age: 30  '  + '\n' +
                            '};  '  + '\n' +
                            '\n' +
                            'const printAge = function () {  '  + '\n' +
                            '  console.log(this.age);  '  + '\n' +
                            '}  '  + '\n' +
                            '\n' +'\n' +
                            'const printFirstName = () => {  '  + '\n' +
                            '  console.log(this.firstName)  '  + '\n' +
                            '}  '  + '\n' +
                            '\n' +
                            'person1.printAge = printAge;  '  + '\n' +
                            'person2.printAge = printAge;  '  + '\n' +
                            '\n' + 
                            'person1.printFirstName = printFirstName;  '  + '\n' +
                            'person2.printFirstName = printFirstName;  '  + '\n' +
                            '\n' +
                            'person1.printAge();   //1'  + '\n' +
                            'person2.printAge();   //2'  + '\n' +
                            '\n' +
                            'person1.printFirstName();  //3  '  + '\n' +
                            'person2.printFirstName();   //4'
        } language="javascript" />
        </span>,
        answer: (<span><ol>
            <li><Code>19</Code> because the function is being called using an object reference using <Code>person1</Code></li>
            <li><Code>30</Code>  because the function is being called using an object reference using <Code>person2</Code></li>
            <li><Code>undefined</Code> because an the arrow syntax is used to create the function.  The value of <This/> is not bound during execution is has the same valueas it did when
            the function was created.  In this case, in the global execution context, <This/> is set to undfined because of the <Code>use strict</Code> directive.</li>
            <li><Code>undefined</Code> Same reason as above.</li>
            </ol>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'var a = {b:3};  '  + '\n' +
                'a.func = function (){  '  + '\n' +
                '  console.log(this);  '  + '\n' +
                '  (function(){  '  + '\n' +
                '    console.log(this);  '  + '\n' +
                '  })();  '  + '\n' +
                '};  '  + '\n' +
                'a.func();'
        } language="javascript" />
        </span>,
        answer: (<span><p>There are two outputs.  The first is the object <Code>a</Code> since <Code>func</Code> is being invoked using a object reference.</p>
        <p>The second output will output <Code>window</Code> since when this self invoking function is executed, it is executed in the a functional execution context where <This/>
        is set to the defaut value.  If <Code>use strict</Code> was in use, it would output <Code>undefined</Code> instead of window.</p>
        </span>)
    },
    {
        question: <span>What is the output from the following and why?
            <PrismCode code={'function someFunc() {  '  + '\n' +
                                '  return (  '  + '\n' +
                                '    function() {  '  + '\n' +
                                '      return {  '  + '\n' +
                                '        data: [98, 97, 87, 29],  '  + '\n' +
                                '        someFunc: function() {  '  + '\n' +
                                '          this.indicies.forEach((index) => {  '  + '\n' +
                                '            console.log(this.data[index]);  '  + '\n' +
                                '          })  '  + '\n' +
                                '        }  '  + '\n' +
                                '      };  '  + '\n' +
                                '    }  '  + '\n' +
                                '  )().someFunc;  '  + '\n' +
                                '}  ' 
                                + '\n' + '\n' +
                                'const person = {  '  +  '\n' +
                                '  indicies: [0, 2],  '  +  '\n' +
                                '  data: [73, 95, 38, 45, 13, 39]  '  +  '\n' +
                                '};  '  +  '\n' + '\n' +
                                'person.someFunc = someFunc();  '  +  '\n' +
                                'person.someFunc();   '
        } language="javascript" />
        </span>,
        answer: (<span><p>Lets step though to see what is going on.</p><ol>
            <li>First, the <Code>someFunc</Code> function is being invoked.  It is important to note it is not using a object reference, though it is returning 
            a function that is assigned to a property in the object <Code>person</Code>.</li>
            <li>In the function, the <Code>someFunc</Code> property of some self-invoking function.</li>
            <li>In this self-invoking function, it returns a object, with a method <Code>someFunc</Code>.  This function is being returned by the outter most 
            <Code>someFunc</Code> method - the one executing a self-invoking function.  Remember, it is not returning the whole object, just the function of it.</li>
            <li>When we call <Code>someFunc</Code> on person, it is the inner most function in the code.  It loops though the <Code>person.indicies</Code> array
            and executes a function for each index.  </li>
            <li>This inner most function is using the arrow syntax.  This means, <This/> is bound to the context in which the function was created (not executed) and 
            the function is created when the outter <Code>someFunc</Code> is executed.  </li>
            <li>When this outter <Code>someFunc</Code> function is executed, the context is within the <Code>person</Code> object. so <This/> in the inner most function 
            references the <Code>person</Code> object.</li>
            <li>The output is 73 and 38.</li>

        </ol>
        </span>)
  },
  {
      question: <span>What is the output from the following and why?
          <PrismCode code={'function Person(firstName) {  '  + '\n' +
                        '  this.firstName = firstName;  '  + '\n' +
                        '  this.someFunc = function() {  '  + '\n' +
                        '    const anotherFunc = () => {  '  + '\n' +
                        '      console.log(this.firstName);  '  + '\n' +
                        '    };  '  + '\n' +'\n' +
                        '    return {  '  + '\n' +
                        '      firsName: \'default\',  '  + '\n' +
                        '      doSomething: function () {   '  + '\n' +
                        '      	anotherFunc();  '  + '\n' +
                        '        console.log(this.firstName);  '  + '\n' +
                        '      }  '  + '\n' +
                        '    }  '  + '\n' +
                        '  }  '  + '\n' +
                        '}  '  + '\n' +'\n' +'\n' +'\n' +
                        'const person1 = new Person(\'Joe\');  '  + '\n' +
                        'const person2 = {firstName: \'Jane\', finalFunc: person1.someFunc().doSomething};  '  + '\n' +
                        'person2.finalFunc();  '
      } language="javascript" />
      </span>,
      answer: (<span> <p>There are 2 outputs.</p>
      <ol>
          <li><Code>Joe</Code> - the first console statement is caused by the log invocation 
          in the arrow function of the <Code>Person</Code> constructor.  The <This/> referenced in the arrow function 
          is bound when the function is defined – which refers to the person named "Joe".  
          This does not change regardless of the different execution contexts this function is executed in later on.</li>
          <li><Code>Jane</Code> - The second console log will display “Jane” because when <Code>finalFunc</Code> is invoked, it is actually invoking the function <Code>doSomething</Code> and
          it is invoked using a reference to the object with first name “Jane” and in this execution context thus <This/> is referencing the object
           with <Code>firstName</Code> "Jane".   It does not matter that the <Code>doSomething</Code> function was initially created in the context of the <Code>Person</Code>
           named "Joe" or the object with <Code>firstName</Code> "default".   <This/> is resolved during the execution of the <Code>doSomething</Code> method.</li> 
      </ol>
      </span>)
  },
  {
      question: <span>What is the output from the following and why?
          <PrismCode code={'"use strict"' + '\n' +
                       'function hello() {  '  + '\n' +
                    '  setTimeout(function() {  '  + '\n' +
                    '    console.log(this.firstName);  '  + '\n' +
                    '  }, 100);  '  + '\n' +
                    '}  '  + '\n' +
                    'hello.call({  '  + '\n' +
                    '  firstName: \'tom\'  '+ '\n' +
                    '});  ' 
      } language="javascript" />
      </span>,
      answer: (<span> <p>The explict binding causes <This/> to reference the object passed into the <Code>call</Code> invocation.  However, 
      when the timeout invokes the function after 100s, the name <Code>undefined</Code> will be printed since this is not being invoked in 
      using a object reference nor in the context of the object with firstname "Tom".  To clarify why, run the code below to see what you get.</p>
      <PrismCode code={'"use strict"  '  + '\n' +'\n' +
                        'function hello() {  '  + '\n' +
                        '	console.log(this.firstName);  '  + '\n' +
                        '}  '  + '\n' +
                        '\n' +
                        'hello.call({  '  + '\n' +
                        '  firstName: \'tom\'  '  + '\n' +
                        '});  '
      } language="javascript" />
      </span>)
  }
];

export { questions, summary, introduction };