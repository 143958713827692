import React from 'react';
import LeetQuestion from './LeetQuestion';
import { MathJax } from "better-react-mathjax";
import TransitionFunction from './TransitionFunction';
import InlinePrismCode from '../../../../component/interview-questions/InlinePrismCode';
import CodeSnip from '../CodeSnip';
import Mf from '../Mf'
import MemoizationTable from './MemoizationTable';
const WordBreak = () => {
    const qd = (
        () => {
            return (<>
                <p>
                    Given a string s and a dictionary of strings wordDict, return true if <CodeSnip>s</CodeSnip> can be segmented into a space-separated
                    sequence of one or more dictionary words.
                </p>
                <p>
                    <b>Note</b> that the same word in the dictionary may be reused multiple times in the segmentation.
                </p>
            </>)
        }
    );

    const examples = [
        {
            inputs: "s = \"leetcode\", wordDict = [\"leet\",\"code\"]",
            outputs: "true",
            explaination: "Return true because \"leetcode\" can be segmented as \"leet code\"."
        },
        {
            inputs: "s = \"applepenapple\", wordDict = [\"apple\",\"pen\"]",
            outputs: "true",
            explaination: "Return true because \"applepenapple\" can be segmented as \"apple pen apple\".  Note that you are allowed to reuse a dictionary word."
        },
        {
            inputs: "s = \"catsandog\", wordDict = [\"cats\",\"dog\",\"sand\",\"and\",\"cat\"]",
            outputs: "false",
            explaination: "Additional Notes: We can only break up \"andog\" as (\"and\" and \"og\")  OR (\"an\" and \"dog\") and in both cases one of the words is not in the dictonary"
        },
        {
            inputs: "s = \"\", wordDict = []",
            outputs: "true",
            explaination: "Edge case, empty",
            custom: true
        },
    ];

    const BruteForce = (
        <>
            <p>
                In our brute force solution, we check if the first part of the word <Mf inline={true}>{"$S_{(0,m)}$"}</Mf> is in the dictonary,
                <CodeSnip>w</CodeSnip> and <CodeSnip>w.length() == k</CodeSnip>. If it is, then
                we recursively solve the problem for <Mf inline={true}>{"$S_{(k+1,m)}$"}</Mf> and <Mf inline={true}>{"$S_{(0,m)}$"}</Mf>.
                If either are true, we return true.
            </p>
            <p>
                Since we're making two recrusive calls each time we're solving and we can make at most <CodeSnip>wordDict.size() = n</CodeSnip> calls,
                This leads to a runtime of <Mf inline={true}>{"$O(2^{(n)})$"}</Mf>.
            </p>

        </>
    )

    const OverlappingSubproblems = (
        <></>
    );


    const Intuition = (
        <>
            <p>
                For example, say <CodeSnip>s = "leetcode", wordDict = ["leet","code"]</CodeSnip>.
                <CodeSnip>s.length() = m, wordDict.lengh = n, wordDict[i].length() = k</CodeSnip>

            </p>
            <p>
                First, look the first m characeters until we find, characters <Mf inline={true}>{"$0..4$"}</Mf>,  <Mf inline={true}>{"$S_{(0,4)}==leet$"}</Mf>
                that exists in our dictonary.
                We know from the overlapping subproblems that if we remember this fact, we don't have to re-compute it.
            </p>
            <p>
                Start looking at the characters after 4 until we get our next string in the dictonary.  This is <Mf inline={true}>{"$S_{(5, 8)}==code$"}</Mf>.
                So we know characters <Mf inline={true}>{"$S_{(5, 8)}$"}</Mf> are in the dictonary, then we can return true if the subproblem made with characters
                <Mf inline={true}>{"$S_{(0, 4)}$"}</Mf> is also true.   We know this, because we had previously computed this earlier.
            </p>
            <p className="text-center">
                <CodeSnip>dp[i] = true</CodeSnip>, if characters <Mf inline={true}>{"$S_{(0, i)}$"}</Mf> can be matched in our dictonary.
            </p>

        </>
    );

    const memoDataEmpty = [
        ["1", "", "", "", "", "", "", "", ""]
    ]
    const memoDataFilled = [
        ["1", "0", "0", "0", "1", "0", "0", "0", "1"]
    ]
    const rowHeaderData = [];
    const columnHeaderData = ["\"\"", "l", "e", "e", "t", "c", "o", "d", "e"];
    const MemoTable = <MemoizationTable rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={memoDataEmpty} />

    const BottomUp = (
        <>
            <p>
                Lets consider an example: <CodeSnip>text1="GXTXAYB" text2="AGGTAB"</CodeSnip> again.
            </p>
            <p>
                First, we want to reserve <CodeSnip>dp[0] = true</CodeSnip> as our base case.  This means our characters start at index 1.
            </p>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    {MemoTable}
                </div>
                <div className="col-4"></div>
            </div>
            <p>
                As we move from left to right, for each index i, we want to compute each string:
                <Mf inline={true}>{"$S_{(0, 1)}$"}</Mf>, <Mf inline={true}>{"$S_{(0, 2)}$"}</Mf>..<Mf inline={true}>{"$S_{(0, i)}$"}</Mf>
            </p>
            <p>
                And if any of them are in the dictonary, find the value of <CodeSnip>dp[i-len(<Mf inline={true}>{"$S_{(0, 2)}$"}</Mf>)]</CodeSnip>
                and if we find a value of <CodeSnip>true</CodeSnip>, then stop and move on to the next index.
            </p>

            <p>
                As we start at row 1 and work our way across and down, we get the following table result, we at the end we simply return 
                <CodeSnip>dp[M]</CodeSnip>.
            </p>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                <MemoizationTable rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={memoDataFilled} />
                </div>
                <div className="col-4"></div>
            </div>
            <p>
                This gives us a runtime complexity of:
            </p>
            <div className="text-center">
                <MathJax>{"$O(m*n*n) = O(m*n^2)$"}</MathJax> where <CodeSnip>len(wordDict) = n and len(s)=m</CodeSnip>
            </div>
        </>
    );

    const transitionFunction = (
        <>
            <div className="">
                <TransitionFunction statement={String.raw`$$f(i)=\begin{cases}f(i-len(w_k))  && where\mspace{5mu} w_k \mspace{5mu} is \mspace{5mu} 
                a \mspace{5mu} word \mspace{5mu} in \mspace{5mu} the \mspace{5mu}\mspace{5mu} dictonary \\false && otherwise \end{cases}$$`} />
            </div>
        </>
    )

    return <LeetQuestion
        questionDefinition={qd}
        title="139. Word Break"
        examples={examples}
        transitionFunction={transitionFunction}
        bruteForce={BruteForce}
        overlappingSubproblems={OverlappingSubproblems}
        intuition={Intuition}
        bottomUp={BottomUp}
    />
};

export default WordBreak;