import React from "react"
import PrismCode from './PrismCode';
import ANSWER_TYPE from '../../constants/appConstants';

const summary = "";
const introduction = "In this section, I've been collecting various React exercises for interview prep or questions I've been asked." +
    "So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" +
    "my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: (<><p>When you click on a square, it should put a 'X' on it.  However, when I click, nothing is happeningCan you fix it?</p>
        <a href="https://codepen.io/canosielabs/pen/vYrvQxo" target="_blank">Click here for the codepen.</a>
        </>)      
        ,
        answerType: ANSWER_TYPE.OE,
        answer: (<span>            
  
            <a href="https://codepen.io/canosielabs/pen/vYrvQxo" target="_blank">The solution can be found here.</a>
        </span>)
    },
];

export { questions, summary, introduction };