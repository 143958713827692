import React from "react"
import PrismCode from './PrismCode';
const summary = "";
const introduction = "Over the years, I’ve created, been asked, and acquired a number of interview questions." + 
"So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" + 
"my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: <span>What is Spring's application context?</span>,
        answer: (<span>This is sometimes refered to as the IoC (Inversion of Control) container. Loads configuration files (if necessary) Contains access to beans, factory methods,
        configurations, ability to load configurations, etc</span>)
    },
    {
        question: <span>What are some ways to configure a sprint application...ie configure an application context?</span>,
        answer: (<span><ul>
            <li>Annotation based eg define beans using annotations.</li>
            <li>Programmatically in java.</li>
            <li>XML based eg define beans using xml.</li>
            </ul></span>)
    },
    {
        question: <span>Benefits of using Spring framework?</span>,
        answer: (<span><ul>
            <li>Use of DI and IoC.</li>
            <li>Easy to test due to IoC.</li>
            <li>Spring MVC as a framework is easy to test with.</li>
            </ul></span>)
    },
    {
        question: <span>What's IoC and dependency Injection?</span>,
        answer: (<span><b>Inversion of Control</b> is when a class does not perform tasks directy but delegates to other
            classes/objects.  It requires these objects as a dependency to complete its task.  More specifically, the dependencies
            are not configured by invoking class but are created and configured independantly of the user.
            <br/><br/>
            <b>Dependency Injection</b> refers to the process in which a object declars the list of dependencies required to
            perform a task.  At runtime, a container (sometimes called a IoC Container) supplies instances of the required
            dependencies to the object.
            </span>)
    },
    {
        question: <span>What is the difference between Spring and Spring Boot?</span>,
        answer: (<span>Spring and Spring MVC are frameworks to provide features to make development easier.  This includes
            dependenc injection, data binding, etc.  It is upto the user to determine how the framework is configured and structure of
            the code. <br /> <br />
            <ul>
                <li>
                    Springboot takes a openionated view (and defaut configurations) on how various commonly used parts of Spring should be used.  
                    For example, it auto-configures hibernate if the hibernate ORM is found on the classpath.
                </li>
                <li>
                It also comes with an embedded server for development or production.
                </li>
                <li>
                    It allows developers to quickly bootstrap the development of Spring applications.
                </li>
            </ul>              
            </span>)
    },
    {
        question: <span>What are some of the advantages of Springboot?</span>,
        answer: (<span>
            <ul>
                <li>
                    Avoids creation of boilerplate code, espically XML configurations.
                </li>
                <li>
                    Easy creation deployable artifacts (WAR files).
                </li>
                <li>
                    Automatically introduces the concept of serparated development envrionments (i.e. LOCAL, DEV, PROD, etc)
                </li>
                <li>
                    Easier to integrate Spring with other dependencies like logging, ORM, JDBC, Spring Security.
                </li>
                <li>
                    Bootstraped development.  A embedded server is included.
                </li>
                <li>
                    Starter POM files for Maven configuration.
                </li>
            </ul>              
            </span>)
    },
    {
        question: <span>What are the differen ways to change the default Port in Springboot?</span>,
        answer: (<span>
            <ul>
                <li>
                    In the user properties file.  The property called server.port.
                </li>
                <li>
                    In the main class (annotated by @SpringBootApplication), we can set the server port in Java code.
                </li>
                <li>
                    On the command line when the embedded server starts up.
                </li>
            </ul>              
            </span>)
    },
    {
        question: <span>If a class requires a dependency, but the there are two or more beans that satify the type matching,
             can you do something to distunguish those beans?</span>,
        answer: (<span>
            If there are two beans that match a particular type declaration, those classes will need to be named when annotated.
            Also, the @Qualifier annotaition can also be used.  <br /><br />
            For the classes requiring the dependency, along with the @Autowired annotation, the @Qualifier annotation must be used
            to distunguish between the required beans of the same type.
            </span>)
    },

    {
        question: <span>From the previous question, does it mean when the automatic autowiring notices there are two or more matching beans? Can you give an example?</span>,
        answer: (
            <span>
                One common case if our declaration is a super class and our intention is to populate it with subclasses. 
                            <PrismCode code={`public class DocumentController {    
    @Autowired
    private DocumentService documentService;
    
    // implementation deails
}

// DocumentService.java
@Component
public class DocumentService {        
    // implementation deails
}

// TaxService.java
@Component
public class TaxService {        
    // implementation deails
}

// IncomeService.java
@Component
public class IncomeService {        
    // implementation deails
}
`} language="java" />

Springboot will throw a NoUniqueBeanDefinitionException since both TaxService and IncomeService match the autowiring by type process.   We can fix this by using the 
@Qualifier annotation.
<PrismCode code={`public class DocumentController {    
    @Autowired
    @Qualifier("taxService")
    private DocumentService documentService;
    
    // implementation deails
}

// DocumentService.java
@Component
public interface DocumentService {        
    // implementation deails
}

// TaxService.java
@Component
@Qualifier("taxService")
public class TaxService implements DocumentService {        
    // implementation deails
}

// IncomeService.java
@Component
@Qualifier("incomeService")
public class IncomeService  implements DocumentService {        
    // implementation deails
}
`} language="java" />
            </span>
        )
    },
    {
        question: <span>Will this also throw a NoUniqueBeanDefinitionException?
<PrismCode code={`public class DocumentController {    
    @Autowired
    private DocumentService taxService;
    
    @Autowired
    private DocumentService incomeService;
    
    // implementation deails
}

// DocumentService.java
@Component
public interface DocumentService {        
    // implementation deails
}

// TaxService.java
@Component
public class TaxService implements DocumentService {        
    // implementation deails
}

// IncomeService.java
@Component
public class IncomeService  implements DocumentService {        
    // implementation deails
}
`} language="java" />

        </span>,
        answer: (<span>
            No.  Because Spring will also attempt to inject the dependency based on the name of the field to inject.
            In this case, the names give enough hints for Spring to do the injection.
            </span>)
    },

    {
        question: <span>What are Spring Profiles? What is one example on how they are used?</span>,
        answer: (<span>
            Many organizations have the concept of different envrionments such as DEV, QA, and PROD (amont others).  In most cases, 
            the configurations for these envrionments are different.  Spring has the ability to provide (load) different configuration files 
            on the profile set when the application is run.  This allows configurations of different environments to remain separate. 
            The profile can be set as a JVM argument to the embedded server or property file value.
            </span>)
    },
    {
        question: <span>What does the @RestController annotation do?</span>,
        answer: (<span>
            It includes @Controller and @ResponseBody.  It is used to denote controller methods that return data (like JSON)
            instead of a view.
            </span>)
    },
    {
        question: <span>What are Springboot starters?</span>,
        answer: (<span>
            Spring Boot provides a number of starters that will automatically add required jars to the classpath. 
            Spring Boot built-in starters make development easier and rapid. They are dependency descriptors thus
            list transitive dependencies with versions that are tested to work together to save you time from trying 
            to put libraries together yourself.
            </span>)
    },
    {
        question: <span>What is Spring Boot Actuator?</span>,
        answer: (<span>
            Spring Boot provides actuator to monitor and manage our application. Actuator is a tool which has HTTP endpoints. when application is pushed to production, you can choose to manage and monitor your application using HTTP endpoints.
            </span>)
    },
    {
        question: <span>What is the high level process of connecting your Sprnigboot app to a database using Hibernate?</span>,
        answer: (<span><ul>
            <li>Springboot provides a JPA starter which will include the required JPA libraries.
            The default JPA implementation is Hibernate
            </li>
            <li>Add as a dependency the required connector library.</li>
        </ul>
            </span>)
   },
   {
       question: <span>What does the @RequestMapping annotation do?</span>,
       answer: (<span><ul>
           <li>It maps a HTTP request route to a controller method used to process the request.
           </li>
           <li>The second property, method allows specailized routing based on HTTP type in addtion to the URL.</li>
           <li>Related mappings include @GetMapping, @PostMapping, @PutMapping, etc - which are used as a shortcut
               instead of providing the method argument in @RequestMapping.
           </li>
       </ul>
           </span>)
   },
   {
       question: <span>What does the @SpringBootApplication  annotation do?</span>,
       answer: (<span>Many Spring Boot developers like their apps to use auto-configuration,
           component scan and be able to define extra configuration on their "application class". 
           A single @SpringBootApplication annotation can be used to enable those three features.
           <br/><br/>
           In otherwords, the @SpringBootApplication annotation is equivalent to using @Configuration,
            @EnableAutoConfiguration, and @ComponentScan with their default attributes
           </span>)
   },
   {
       question: <span>Why is Springboot conisdered opinionated?</span>,
       answer: (<span>Since Springboot provides a default setup to many features, it must
           make a judgment what it thinks most users will use the features.  Though this resolves
           much of the boilerplate configurations developers have to do over and over, there are some
           specific situations that might apply.  Springboot always provides some form of override.
           <br/><br/>
           you need to use either @SpringBootApplication or @EnableAutoConfiguration 
           annotations on the Main class to enable the auto-configuration feature.
           </span>)
   },
   {
       question: <span>What are some of the advantages of using the embedded server?</span>,
       answer: (<span>
           <ul>
            <li>
            Without using the embdded server, you will have to install the Tomcat server
           on your servers and deploy the war file.  It also With embedded servers, you can run the application as a normal
           executable Java application - simplifying the PROD deployment process.
            </li>
            <li>
                Most developers use the embedded server for development.  This means you production server has a large setup difference
                and configuration to the dev environment (which can lead to problems).  Using the embedded server on both DEV and PROD
                can unify some of these configurations across environments.                
            </li>
           </ul>
           </span>)
   },
   {
       question: <span>What are some use case differences between @Value and @ConfigurationProperties?</span>,
       answer: (<span>@Value is used when you need to inject a property from a property file in a bean.  However, this can mean
           property configurations are spread out thoughout your appilcation.  @ConfigurationProperties can be used to create a single
           bean which contains all your properties in application.properties or only those with a specific prefix.  We call this process "Configuration as a service".
           The @PropertySource("file-name") anotation can be used if you want the configurations to be loaded from a file other than application.properties.
           </span>)
   }

];

export {questions, summary, introduction};