import React from 'react';
import Example from '../Example';

const ExampleRow = ({ inputs, outputs, explaination, number, custom }) => {

    return (
        <div className="row ">
            <div className="col">
                <div className="example-num">Example {number}{custom ? "*" : ""}:</div>
                <Example inputs={inputs} outputs={outputs} explaination={explaination} />
            </div>
        </div>
    )
};

export default ExampleRow;