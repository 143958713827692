import { useEffect,useState  } from "react";
import ApiService from "../../service/ApiService";
import SearchResultRow from './SearchResultRow';
import { LeetSearchResultType } from '../../service/ApiService';


const SearchResultContainer = ():JSX.Element  => {

    const [questions, setQuestions ] = useState<LeetSearchResultType[]>([]);
    useEffect(() => {
        ApiService.searchQuestionsLeet().then(data => {
            setQuestions(data);
        });
    }, []);

    const rows = questions.map(q => {
        return (
            <SearchResultRow key={q.questionFrontendId} question={q} />
        )
    });

    return (
        <div className="row">
            <div className="col">
                Search Results
                <table>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Difficulty</th>
                            <th>Frequency</th>
                        </tr>
                        {rows}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SearchResultContainer;