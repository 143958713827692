import React from "react"
import ShowSourceService from '../../service/ShowSourceService';

export class InlinePrismCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showCode: true};
    this.ref = React.createRef()
    this.handleCodeOptionChange = this.handleCodeOptionChange.bind(this);
  }

  componentDidMount() {
    this.highlight()
  }
  componentDidUpdate() {
   // this.highlight()
  }

  handleCodeOptionChange() {
    this.setState({showCode: !this.state.showCode});
  }

  highlight() {
  setTimeout(() => window.Prism.highlightAll(), 0)
  }
  render() {
    const { code } = this.props
    return (
      <React.Fragment>
        
          <code className="language-java">
            {code}
          </code>
      
      </React.Fragment>
    )
  }
}
export default InlinePrismCode;