const URL = {
  LANDING: '/',
  ARTICLES: '/articles',
  MOTIVATION: '/motivation',
  TEAM: '/team',
  JAVA_INTERVIEW_QUESTIONS: '/interview-questions/java/lambda',
  JAVA_STREAM_INTERVIEW_QUESTIONS: '/interview-questions/java/stream',
  JAVA_GENERAL_INTERVIEW_QUESTIONS: '/interview-questions/java/lambda',
  JAVA_HASH_INTERVIEW_QUESTIONS: '/interview-questions/java/general2',
  SPRING_INTERVIEW_QUESTIONS: '/interview-questions/java/spring',

  FULL_STACK_SETUP: '/interview-questions/fullstack/setup',
  JS_INTERVIEW_QUESTIONS_THIS: '/interview-questions/javascript/this',
  JS_INTERVIEW_QUESTIONS_CLOSURE: '/interview-questions/javascript/closure',
  JS_REACT_EXERCISES: '/interview-questions/javascript/react-exercises',
  JS_INTERVIEW_EX: '/interview-questions/javascript/exercises',
  LEET_HOME: '/interview-questions/leet',
  LEET_SEARCH: '/interview-questions/leet/search',

  LEET_QUESTIONS: {
    KNAPSACK_HOME: '/interview-questions/leet/knapsack',
    LCS: '/interview-questions/leet/lcs',
    COIN_CHANGE: '/interview-questions/leet/coin-change',
    LPS: '/interview-questions/leet/longest-palindromic-substring',
    WORD_BREAK: '/interview-questions/leet/word-break',
    UNIQUE_BST: '/interview-questions/leet/unique-bst',
    MAX_SUB_ARRAY: '/interview-questions/leet/max-sub-array',
  }  
};
export default URL;

