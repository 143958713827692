import React from 'react';
import { MathJax, MathJaxContext } from "better-react-mathjax";

const TransitionFunction = ({statement}) => {

    return (
        <MathJax>{statement}</MathJax>

    );
};

export default TransitionFunction;