import React from 'react';

const MotivationPage = () => {
    return (
		<div className="row ">
			<div className="col">
			</div>
			<div className="col-sm-10 col-md-6">
				<div className="heading" id="motivation-head">Our Motivation</div>
				<div className="sub-heading2">To Have Fun and Create Content</div>
				<p>
					A core group of us initially started out by individually writing blogs for fun. As we progressed in
					our careers, we started helping &amp; mentoring other developers who had questions. Though we initially created
					content to help other developers, as our love for creating things grew, we expended to other topics.
				</p>
				<div className="sub-heading2">Software Development is More Than Coding</div>
				<p>
					We heard a growing number of concerns around Twitter, Stack Overflow, etc being an rough
					place to get mentorship or have discussions.
				</p>
				<p>This included topics such as:</p>
				<ul className="custom-bullets">
					<li>You get bullied easily and everything is seen as black or white.</li>
					<li>People often say things to gain followers instead of providing real, practical advice.</li>
					<li>It's hard to discern what is good versus bad advice</li>
				</ul>
				<p>
					Some of these concerns are inherent problems with the platform (i.e. character limits), while others are issues
					surrounding conflict of interest.
					Hence, we decided to look into various topics of interest, generate meaningful content &amp; decide the
					best medium (i.e twitter, blogs, videos etc.) to present that information effectively.
				</p>
				<p>
					We realized that though there are many good places to learn how to code, there are few places where
					you can learn about industry, practical advice, and effective 'next steps'.
				</p>
				<p>
					So that leads to where we are today - Canosie Labs: A group of people in various stages of their careers
					pooling contributing information together.
				</p>

				<br /><br />

                <div className="social-media-links">
            <a href="https://twitter.com/canosielabs" target="_blank"><img src="https://canosielabs.com/img/twitter.png"
                className="social-media-link" /></a>
            <a href="https://www.instagram.com/canosielabs/" target="_blank"><img src="https://canosielabs.com/img/instagram.png"
                className="social-media-link" /></a>
          </div>
			</div>
			<div className="col"></div>
		</div>
    )
};

export default MotivationPage;