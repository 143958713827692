import React from 'react';
import LeetQuestion from './LeetQuestion';
import { MathJax } from "better-react-mathjax";
import TransitionFunction from './TransitionFunction';
import InlinePrismCode from '../../../../component/interview-questions/InlinePrismCode';
import CodeSnip from '../CodeSnip';
import MemoizationTable from './MemoizationTable';
const CoinChange = () => {

    const qd = (
        () => {
            return (<>
                <p>
                    You are given an integer array <CodeSnip>coins</CodeSnip> representing coins of different denominations and an integer amount representing a total amount of money.
                </p>
                <p>
                    Return the fewest number of coins that you need to make up that amount.
                    If that amount of money cannot be made up by any combination of the coins, return <CodeSnip>-1</CodeSnip>.
                </p>
                <p>
                    You may assume that you have an infinite number of each kind of coin.
                </p>
            </>)
        }
    );

    const examples = [
        {
            inputs: "coins = [1,2,5], amount = 11",
            outputs: "3",
            explaination: "11 = 5 + 5 + 1"
        },
        {
            inputs: "coins = [2], amount = 3",
            outputs: "-1",
            explaination: ""
        },
        {
            inputs: "coins = [1], amount = 0",
            outputs: "0",
            explaination: ""
        }
    ];

    const BruteForce = (
        <>
            <p>
                A brute for solution is to use <CodeSnip>DFS</CodeSnip>.  Consider each coin and then for the chosen coin, calcuate the new remaining amount if used and then recursively
                compute the smallest number of coins required make the new total.
            </p>
            <p>
                At each sum amount, we are making <CodeSnip>n</CodeSnip> decisions.  Thus in the worst case, we are making up to <CodeSnip>S</CodeSnip> recursive
                calls(where <CodeSnip>S</CodeSnip> the target sum) and at each sum, we are iterating though <CodeSnip>n</CodeSnip> coins.
                This leads to a runtime of <MathJax inline={true}>{"$O(S^N)$"}</MathJax>
            </p>
        </>
    )

    const OverlappingSubproblems = (
        <>
            <p>
                Yes.  In the example: <CodeSnip>coins = [1,2], amount = 11</CodeSnip>,  as we make recursive calls with the new subtotal, we can see that
                the subtotal of <CodeSnip>9</CodeSnip> can be achive two ways, once using two coins of value <CodeSnip>1</CodeSnip> or one coin of value <CodeSnip>2</CodeSnip>.
                Our brute force solution will recompute the problem for subproblem target total 7 twice in this situation.
            </p>
            <p>
                When we make the recursive call, this is actually a subproblem of the original problem but with a small target total.
            </p>
        </>
    );


    const Intuition = (
        <>
            <p>
                Our brute force solution actually leads us into our optimal solution using dynamic programing or memoization.
            </p>
            <p>
                At each recursive call we can see that the only changing values are the target total.  This is because we have a infinite amount of each coin.
                We do not need to track the kinds of coins used, just the new total.
            </p>
            <p>
                Thus if we save the smallest number of coins used for a specific subtotal, we can reuse this value if we ever encounter a subproblem with this target amount.
                We will reuse it if we look at the call stack of the brute force solution.  At most, the number of subproblems will be the target amount, <CodeSnip>S</CodeSnip>.

            </p>
            <p className="text-center">
                <CodeSnip>dp[i] = the smallest number of coins used to create the s i</CodeSnip>
            </p>

        </>
    );

    const memoDataEmpty = [
        ["","", "", "", "", "", "", "", "", "", ""],
    ]

    const rowHeaderData = [];
    const columnHeaderData = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
    const MemoTable = <MemoizationTable rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={memoDataEmpty} />

    const BottomUp = (
        <>
            <p>
                Lets consider an example: <CodeSnip>coins="[1, 2, 5]" amount="11"</CodeSnip>.
            </p>
            <p>
                First, let's create our 1-D array where the columns represent a target amount and the values cointain the smallest number of coins to
                sum to the target.
            </p>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    {MemoTable}
                </div>
                <div className="col-4"></div>
            </div>
            <p>
                We'll then evaulate each column one at a time from <MathJax inline={true} >{"$1..n$"}</MathJax>.  At each total, for each coin
                we will compute the new total by subtracting the current sum by the coin.  Since this new total is less than the current total,
                we know we've already computed it in our array.  So we do a lookup <CodeSnip>dp[i - coins[k]]</CodeSnip> where <CodeSnip>i</CodeSnip> is our
                total and k is the coin we're considering.  We do this for each coin and take the minimum value.
            </p>
            <p>
                That is, we compute: <CodeSnip>dp[i] = min(dp[i - coins[0]], dp[i - coins[1]], ..., dp[i - coins[n]])</CodeSnip>
            </p>
            <p>
                Special care is taken that we do not compute <CodeSnip>dp[i]</CodeSnip> when <CodeSnip>{"i < 0"}</CodeSnip>.
            </p>
            <p>
                As we start at row 1 and work our way across and down, we get the following table result:
            </p>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    <MemoizationTable rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={[[1, 1, 2, 2, 1, 2, 2, 3, 3, 2, 3]]} />
                </div>
                <div className="col-4"></div>
            </div>
            <p>
                With the answer at <CodeSnip>dp[n-1]=3</CodeSnip> 
            </p>
        </>
    );

    const transitionFunction = (
        <>
            <div className="">
                <TransitionFunction statement={String.raw`$$f(i)=min\begin{cases}f(i-coins[0]) \\f(i-coins[1]) \\... \\f(i-coins[n]) \end{cases}$$`} />
            </div>
        </>
    )
    return <LeetQuestion
        questionDefinition={qd}
        title="322. Coin Change"
        examples={examples}
        transitionFunction={transitionFunction}
        bruteForce={BruteForce}
        overlappingSubproblems={OverlappingSubproblems}
        intuition={Intuition}
        bottomUp={BottomUp}
    />
};

export default CoinChange;