export type StatsType = {
    totalAccepted: string;
    totalSubmission: string;
    totalAcceptedRaw: number;
    totalSubmissionRaw: number;
    acRate: string;
}

export type LeetSearchResultType = {
    status: string;
    questionId: string;
    questionFrontendId: string;
    title: string;
    titleSlug: string;
    difficulty: string;
    isPaidOnly: string;
    stats: StatsType;
    frequency: number;
}

type ResourceUrls = {
    leetQuestionSearch: string;
}

type ApiServiceType = {
    searchQuestionsLeet: () => Promise<LeetSearchResultType[]>
};

const ApiService: ApiServiceType = (() :ApiServiceType => {

      // holder for app url host
      let appUrlBase: string = 'http://local.jeffreyokawa.com/threedm/api/';


    // append  base to the url
    const generateUrl = (url: string): string =>  {
        return appUrlBase + url;
    };

      
   // Application URLs
   const resourceUrls: ResourceUrls = {
        leetQuestionSearch: "leet/search",
    };

    const issueGet = (url: string, headers = { Accept: 'application/json, text/plain, */*' }): Promise<Response> => {
        const endpoint = generateUrl(url);
        return fetch(endpoint, { headers }).then((response) => response.json());
    };



    const searchQuestionsLeet = (): Promise<any> => {
        return issueGet(resourceUrls.leetQuestionSearch);
    };


    return {
        searchQuestionsLeet,
    };
})();


export default ApiService;
