import React from 'react';
import InterviewQuestion from '../../../component/interview-questions/InterviewQuestion';
import {questions, summary, introduction} from '../../../component/interview-questions/JsExercises';

const InterviewQuestionJsExercises = () => {
    return (<InterviewQuestion
        title="JavaScript Interview Excerises"
        introduction={introduction}
        summary={summary}
        questions={questions} 
        featureImage="https://canosielabs.com/img/articles/js/thumbnail-exercises-js.png"
        />);
};

export default InterviewQuestionJsExercises;