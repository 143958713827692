import { useEffect } from "react";
import ApiService from "../../service/ApiService";
import SearchResultContainer from "./SearchResultContainer";

const SearchPage = () => {


    return <div>
        <div className="row">
            <div className="col">
            Search Page
            </div>
        </div>
        <SearchResultContainer />
    </div>;
};

export default SearchPage;