
import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import URL from './constants/routes';
import LandingPage from './screen/landing/LandingPage';
import MotivationPage from './screen/motivation/MotivationPage';
import ArticlesPage from './screen/articles/ArticlesPage';
import InterviewQuestionsLambda from './screen/interview-questions/java/InterviewQuestionsLambda';
import InterviewQuestionsSpring from './screen/interview-questions/java/InterviewQuestionsSpring';
import InterviewQuestionsJsThis from './screen/interview-questions/js/InterviewQuestionsJsThis';
import InterviewQuestionsJsClosure from './screen/interview-questions/js/InterviewQuestionsJsClosure';
import InterviewQuestionJsExercises from './screen/interview-questions/js/InterviewQuestionJsExercises';
import InterviewReactExercises from './screen/interview-questions/js/InterviewReactExercises';

import InterviewQuestionsGeneral from './screen/interview-questions/java/InterviewQuestionsGeneral';
import InterviewPrepFullstack from './screen/interview-questions/java/InterviewPrepFullstack';
import InterviewStream from './screen/interview-questions/java/InterviewStream';
import InterviewQuestionsHashcode from './screen/interview-questions/java/InterviewQuestionsHashcode';
import MainLayout from './component/layout/main-layout/MainLayout';
import LeetPage from './screen/leet/LeetPage';
import Knapsack from './screen/leet/component/Dp/Knapsack';
import Lcs from './screen/leet/component/Dp/Lcs';
import CoinChange from './screen/leet/component/Dp/CoinChange';
import Lps from './screen/leet/component/Dp/Lps';
import WordBreak from './screen/leet/component/Dp/WordBreak';
import UniqueBst from './screen/leet/component/Dp/UniqueBst';
import SearchPage from "./screen/leet/component/search/SearchPage";
import MaxSubarray from "./screen/leet/component/Dp/MaxSubarray";

const root = document.getElementById('root')
const App = () => {

  const { LEET_QUESTIONS } = URL;
  return (
      <BrowserRouter>
        <MainLayout>
            <Routes>
              <Route  path={URL.LANDING} element={<LandingPage/>} />
              <Route  path={URL.ARTICLES} element={<ArticlesPage/>} />
              <Route  path={URL.MOTIVATION} element={<MotivationPage/>} />
              <Route  path={URL.JAVA_INTERVIEW_QUESTIONS} element={<InterviewQuestionsLambda/>} />
              <Route  path={URL.JAVA_GENERAL_INTERVIEW_QUESTIONS} element={<InterviewQuestionsGeneral/>} />
              <Route  path={URL.SPRING_INTERVIEW_QUESTIONS} element={<InterviewQuestionsSpring/>} />
              <Route  path={URL.JS_INTERVIEW_QUESTIONS_THIS} element={<InterviewQuestionsJsThis/>} />
              <Route  path={URL.JS_INTERVIEW_QUESTIONS_CLOSURE} element={<InterviewQuestionsJsClosure/>} />
              <Route  path={URL.JS_INTERVIEW_EX} element={<InterviewQuestionJsExercises/>} />
              <Route  path={URL.FULL_STACK_SETUP} element={<InterviewPrepFullstack/>} />
              
              <Route  path={URL.JS_REACT_EXERCISES} element={<InterviewReactExercises/>} />


              <Route  path={URL.JAVA_HASH_INTERVIEW_QUESTIONS} element={<InterviewQuestionsHashcode/>} />

              <Route  path={URL.JAVA_STREAM_INTERVIEW_QUESTIONS} element={<InterviewStream/>} />
              <Route  path={URL.LEET_HOME} element={<LeetPage/>} />
              <Route  path={URL.LEET_SEARCH} element={<SearchPage/>} />

              <Route  path={LEET_QUESTIONS.KNAPSACK_HOME} element={<Knapsack/>} />
              <Route  path={LEET_QUESTIONS.LCS} element={<Lcs/>} />
              <Route  path={LEET_QUESTIONS.COIN_CHANGE} element={<CoinChange/>} />
              <Route  path={LEET_QUESTIONS.LPS} element={<Lps/>} />
              <Route  path={LEET_QUESTIONS.WORD_BREAK} element={<WordBreak/>} />
              <Route  path={LEET_QUESTIONS.UNIQUE_BST} element={<UniqueBst/>} />
              <Route  path={LEET_QUESTIONS.MAX_SUB_ARRAY} element={<MaxSubarray/>} />
            </Routes>
        </MainLayout>
      </BrowserRouter>
  )
}
export default App;
