import { MathJaxContext } from "better-react-mathjax";
import MATH_JAX_CONFIG from '../../constants';
import ExampleRow from '../../component/row/ExampleRow';
import createQuestionDefinition from '../../component/row/QuestionDefinition';
import '../../Leet.scss';
const LeetQuestion = ({
    questionDefinition,
    title,
    examples,
    transitionFunction,
    bruteForce,
    overlappingSubproblems,
    intuition,
    bottomUp,
}) => {


    const QuestionDefinition = createQuestionDefinition(questionDefinition);

    const exampleRows = examples?.map(({ inputs, outputs, explaination, custom }, index) => {
        return (<ExampleRow inputs={inputs} outputs={outputs} explaination={explaination} custom={custom} key={index} number={index + 1} />);
    });
    return (
        <div className="leet">
            <MathJaxContext config={MATH_JAX_CONFIG}>
                <div className="row leet-title">
                    <div className="col-2"></div>
                    <div className="col-8  text-center">
                        {title}
                    </div>
                    <div className="col-2"></div>
                </div>
                <QuestionDefinition />

                {exampleRows}
                <div className="explaination">
                    <div className="row ">
                        <div className="col">
                            <div className="solution-section">
                                <div className="solution-title">Brute Force</div>
                                <div className="solution">
                                    {bruteForce}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="solution-section">
                                <div className="solution-title">Overlapping Subproblem</div>
                                <div className="solution">
                                    {overlappingSubproblems}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="solution-section">
                                <div className="solution-title">Intuition</div>
                                <div className="solution">
                                    {intuition}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="solution-section">
                                <div className="solution-title">Transition Function</div>
                                <div className="solution">
                                    {transitionFunction}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col">
                            <div className="solution"></div>
                            <div className="solution-title">Bottom Up Memoization</div>
                            <div className="solution">
        
                                    {bottomUp}
                         
                                <div className="solution-title">Top Down Dynamic Programming</div>
                                <div className="solution"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </MathJaxContext>
        </div>
    )
};

export default LeetQuestion;