import React from "react"
import PrismCode from './PrismCode';
const summary = "";
const introduction = "Over the years, I’ve created, been asked, and acquired a number of interview questions." + 
"So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" + 
"my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: <span>What is a functional interface?</span>,
        answer: (<span>Functional interface is a Java interface that defines only one abstract class.  They can contain static methods and default methods.</span>)
    },
    {
        question: <span>Write an example of a functional interface.</span>,
        answer: (<PrismCode code={"@FunctionalInterface\ninterface MyFunctionalInterface {\n    String createString();\n}"} language="java" />)
    },
    {
        question: <span>When of the following are not functional interfaces?
            <PrismCode code={"interface ClassA {\n" +
                "    public static String doSomething() {\n" +
                "        return \"abc\";\n" +
                "    }\n" +
                "\n" +
                "    String createString();\n" +
                "}" +
                "\n" +
                "\n" +
                "public class ClassB {\n" +
                "    public static String doSomething() {\n" +
                "        return \"abc\";\n" +
                "    }\n" +
                "}" +
                "\n" +
                "\n" +
                "interface ClassC {\n" +
                "    String doSomething();\n" +
                "    String doSomethingElse();\n" +
                "}" +
                "\n" +
                "\n" +
                'interface ClassD {\n' +
                '    public static String doSomething() {\n' +
                '        return "abc";\n' +
                '   	}\n' +
                '\n' +
                '   	public default String doSomethingElse() {\n' +
                '   		return "abc";\n' +
                '   	}\n' +
                '\n' +
                '   	String createString();\n' +
                '}'

            } language="java" />
        </span>,
        answer: (<span><ul>
            <li>ClassA - is a functional interface since it has only one abstract method.  The static method is allowed.</li>
            <li>ClassB - is NOT a functional interface since it is not a interface but a Class.</li>
            <li>ClassC - is NOT a functional interface since it has defines abstract methods.</li>
            <li>ClassD - is a functional interface since it has only one abstract method. The static method and default method is allowed.</li>
        </ul>
        </span>)
    },
    {
        question: <span>What are lambda's trying to simplify (before introduction in Java 8)?</span>,
        answer: (<span>Prior to lambda expressions, developers had to create either anonymous or inner classes to pass functionality around.  
            This resulted in a large amount of boilerplate code which caused overall readability problems in code.  </span>)
    },
    {
        question: <span>What are the main parts of an lambda expression?</span>,
        answer: (<span>A lambda is made up of 3 parts:<ul>
            <li>The parameters.</li>
            <li>The arrow -&gt; </li>
            <li>The body, which can be a single expression or a code block.</li>            
        </ul></span>)
    },
    {
        question: <span>If you have a the following lambda expression, create the functional interface that can be used to instantiate them.
        <PrismCode code={'public class FunctionalInterfacePlay {\n' +
                '    public static void main(String[] args) { \n' +
                '        FunctionalInterfacePlay p = new FunctionalInterfacePlay();\n ' +
                '        p.doSomethingElse(() -> "abc"); \n ' +
                '        p.doSomethingElse(() -> Integer.valueOf(0));\n ' +
                '    } \n ' +
                '\n' +
                '    public void doSomethingElse(GenerateRandom me) { \n' +
                '    } \n ' +
                '}  '
            } language="java" />
        </span>,
        answer: (<PrismCode code={'interface GenerateRandom<T> {\n' +
            '    T generateRandom();\n' +
            '}\n'
        } language="java" />)
    },
    {
        question: <span>What is a Lambda expression and what are some of the advantages?</span>,
        answer: (<span>A lambda is a: <ul>
            <li>First class citizen that can be assigned to a variable.</li>
            <li>Is not a method of a class.</li>
            <li>An anonymous method used to implemented the single non-static method in a functional interface.</li>
            <li>Like a method, it defines parameters(accepts arguments) and can optionally return a value.</li>
            <li>Avoids the need to create a full Class (or anonymous Class) simply to implement a single method.</li>
            <li>Supports better by allowing parellel execution when used with Collections and Java's Stream API.</li>
        </ul></span>)
    },
    {
        question: <span>Write a lambda express that accepts a int(primitie) returns the argument plus 4.  For referene, here
            is the functional interface you are being asked to implement.
            <PrismCode code={ '@FunctionalInterface\ninterface AddFourInterface {\n'  + 
            '    int addFour(int value);\n'  + 
            '}\n'
            } language="java" />
        </span>,
        answer: (<PrismCode code={ 'AddFourInterface d = (value) -> value + 4;'
        } language="java" />)
    },
    {
        question: <span>Can you write a method that accepts this lambda as an argument and invokes it?
            <PrismCode code={ '@FunctionalInterface\ninterface AddFourInterface {\n'  + 
            '    int addFour(int value);\n'  + 
            '}\n'+
            '\n'+
            '/**  */'+
            '\n'+
            '\n'+
            'myMethod((value) -> value + 4); // write method myMethod' 
            } language="java" />
        </span>,
        answer: (<PrismCode code={ 'public void myMethod(AddFourInterface exp) {\n    exp.addFour(2);\n};'
        } language="java" />)
    },
    {
        question: <span>What are the conditions when a method reference can be passed as an argument instead of a lambda expression?        
        </span>,
        answer: (<span>The method must have the same paremters and return type as the lamba expression.
        </span>)
    },
    {
        question: <span>What does the @FunctionalInterface do?       
        </span>,
        answer: (<span>It is used to indicate that an interface is intended to be used as a functional interface. 
            The compiler can do compile time checks to ensure interfaces annotated with @FunctionalInteface are valid
            functional interfaces.
        </span>)
    },
    {
        question: <span>Given the following classes, what are the different ways to invoke the Play#addMethod() on instance p?
            <PrismCode code={  'public class AddFour {\n'  + 
            '    public int addFour(int value) {\n'  + 
            '        return value + 4;\n'  + 
            '    }\n'  + 
            '\n'  + 
            '    public static int addFourStatic(int value) {\n'  + 
            '        return value + 4;\n'  + 
            '    }\n'  + 
            '}\n' +
            '\n'+
            '/**  */'+
            '\n'+
            '\n'+
            'public class Play {\n    public void addFour(int value, AddFourInterface exp) {\n'  + 
            '        exp.addFour(value);\n'  + 
            '    }\n}' +
            '\n'+
            '/**  */'+
            '\n'+
            '\n'+
            'Play p = new Play();\n'
            } language="java" />
        </span>,
        answer: (<span>It can be invoked 3 ways:<PrismCode code={ 'p.addFour(2, addFourInstance::addFour); \\ instance method reference \n'+
                'p.addFour(2, AddFour::addFourStatic); \\ static method reference \n' + 
                'p.addFour(2, (value) -> value + 4); \\ lambda expression'
        } language="java" /></span>),        
    },
    {
        question: <span>What does variable capture mean in the context of lambdas?  
        </span>,
        answer: (<span>Lambda's have access to local, static, and instance variables declared in the outer spce.  
        </span>)
    },
    {
        question: <span>What does effectively final mean in the context of lambdas?       
        </span>,
        answer: (<span>This means two things:
            1) A lambda cannot change the value of variables used in the lambda that were declared by the enclosing scope. 
            2) The value of the variable cannot be changed outside the scope of the lambda.
            Combining the two, this 'effectively' makes local variables used in the lambda 'final'. 
            The following is not allowed.   
            <PrismCode code={ 'interface  MyInterface {\n    void something();\n}' +
                '\n\n/**  */\n\n'+
                'int i = 0 \n'+
                'MyInterface m = () -> { \n'+
                '    i = 3 // not allowed \n'+
                '}'
        } language="java" />
        </span>)
    },
    {
        question: <span>What is the type of lambda expression and what is lambda target type?    
        </span>,
        answer: (<div>
            <PrismCode code={ '(int value) -> value + 4'
        } language="java" />
        <p>  Let's consider this lambda expression:</p>
          <ul>
              <li>
              A lambda express on its own such as on it's own without context has no type. 
              </li>
              <li>
              We CANNOT  determine a type but we can <b>describe</b> the expression as taking a int argument, adding 4 and returning the result, a int.
              </li>
              <li>
              A lambda expression on its own isn't even valid Java syntax.  Try it out, the compiler will error.  
              </li> 
              <li>
              Its type can be any functional interface type with an taking a int argument andr eturn an int.
              </li>             
          </ul>
        <p>What is meant by target type?</p>
        <p>The context in which a lambda expression is used determines a type. This type is called the target type.  
            Consider the following code, assumping that AddFour is a functional interface whos parameters and return type match our lambda expression:
        </p>
        <PrismCode code={ 'AddFour f = (int value) -> value + 4'
        } language="java" />        
        <p>The compiler will look at the left-side of the assignment expression and can infer the lambda's type to be AddFour and is the lambda's target type.
            It is important to know that the lambda expression can be of different types based on their context:
        </p>
        <PrismCode code={ 'interface  AddFourOne {\n    int addFour(int value);\n}' +
           '\n\n/**  */\n\n'+
        'interface  AddFourTwo {\n    int addFour(int value);\n}' +
        '\n\n/**  */\n\n'+
        'AddFourOne interface1 =  (int value) -> value + 4;\n'+
        'AddFourTwo interface2 =  (int value) -> value + 4;\n'+
        'interface1 = interface2; // not valid'
        } language="java" />
        <p>In the above code, the lambda expressions are identical, however, the context in which they are used are different thus, their types are different.
        Example locations where target typing occurs include:   </p>
        <ul>
            <li>Variable delcarations</li>
            <li>Assignments</li>
            <li>Return statements</li>
            <li>Method or constructor arguments</li>
        </ul>
     

        </div>)
    },
    {
        question: <span>Which are the following are valid lambda expressions and if not, why?   
                <PrismCode code={ 'interface AddTwo<T> {\n    T addTwo(T v1, T v2);\n}'+
                      '\n\n/**  */\n\n'+
                    'AddTwo<Integer> d1 = (Integer v1, Integer v2) -> { return v1 + v2; };  // 1 \n'+
                    'AddTwo<Integer> d2 = (Integer v1, Integer v2) -> v1 + v2;  // 2\n'+
                    'AddTwo<Integer> d3 = (Integer v1, v2) -> { return v1 + v2; }; // 3\n'+
                    'AddTwo<String> d4 = (v1, v2) -> v1 + v2; // 4\n'+
                    'AddTwo<Integer> d5 = (v1, v2) -> v1 + v2; // 5 \n'+
                    'AddTwo<Integer> d6 =  (v1, v2) -> { return v1 + 4; }; // 6\n'+
                    'AddTwo<String> d7 =  (v1, v2) -> { // 7\n'+
                    '    System.out.println(3);\n'+
                    '    return v1 + v2;\n'+
                    '};\n'+
                    'AddTwo d8 = (value, value2) -> // 8\n'+
                    '    System.out.println(3);\n'+
                    '    return value + 4;\n'		
        } language="java" />    
        </span>,
        answer: (<span><ol>
            <li>Valid</li>
            <li>Valid, a single expression lambda has a implicit return statement.</li>
            <li>Not valid.  You cannot have a lambda expression where only a subset of the prameters type are based on context.  Either all or nothing.</li>
            <li>Valid.  All parameter types are based on context and since has a implict return statement at the end.  In this case, the infered context is String.  
                This lambda performs a String contination and returns a String.
            </li>
            <li>Valid.  Same as (4), but the parameter types are infered as Integers</li>
            <li>Valid.  The return statement is explicit since this is one line lambda code block.</li>
            <li>Valid.  This is a valid lambda code block.</li>
            <li>Invalid.  This is a lambda expression has multiple statements and should be expressed as a code block.  It is missing the curly braces.</li>
        </ol>
        </span>),

    },
    {
        question: <span>Describe the different parts of a lambda and which ones are optional?
        </span>,
        answer: (<span>
            <ul>
                <li>Parameter types - These are optional but if a type is provided for at least one parameter, all parameter types must be declared.</li>
                <li>Parameter parentheses - They are optional only when there is a single parameter.</li>
                <li>Code block curly braces - They are optional for single statement lambda expressions.</li>
                <li>Return statement - Signle expression lambdas do not need a return statement.  Expressions written as code blocks require a return statement,
                    even if there is only a signle statement in the block.
                    If the lambda expression does not return an value, the return statement is optional.
                </li>
            </ul>
        </span>)
    },
    {
        question: <span>What conditions must be met to match a lambda expression to a interface?  Does it have to be declared a functional interface?</span>,
        answer: (<span>No, it does not have to be a functional interface.
            <ol>
            <li>The interface must have one abstract method.</li>
            <li>The return type of the abstract method must match the return type of the expression.</li>
            <li>The parameters of the abstract method must match of the return type of the expression.</li>            
        </ol>
        It must be noted that, though parameters and return types must match the abstract method, lambda expressions can omit parameter types. 
        In this case, the types are infered by the compiler, but a valid inference of types must be made.</span>)
    },
    {
        question: <span>How are lambda expressions commonly used?</span>,
        answer: (<span>Lambda expressions are often used to define inline implementation of a functional interface. 
            An example is how we provide Predicate implementations in the Java Stream API on Collections.  Lambdas are also used
            in situations where we want the user of a method to pass in functions as a first claass citizen to be invoked by the method.
        </span>)
    },
    {
        question: <span>What are some of the differences between a normal and functional interface?</span>,
        answer: (<span>
            <ul>
                <li>Functional interfaces can be annotated with the @FunctionalInterface annotation</li>
                <li>Functional interfaces contain at most one abstract method.  Normal interfaces can have many.</li>
            </ul>
        </span>)
    },
    {
        question: <span>What happens if you add more than one abstract method to this interface?
            <PrismCode code={  '@FunctionalInterface\n'  + 
                'interface MyFunctionalInterface {\n'  + 
                '    String createString(String input);\n'  + 
                '    String doSomethingElse(String input);\n'  + 
                '}' 
        } language="java" /> 
        </span>,
        answer: (<span>
            This code will not compile.  The annotation @FunctionalInterface ensures that the interface 
            can only contain one abstract method.  This interface has two.
        </span>)
    },
];

export {questions, summary, introduction};