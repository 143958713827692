import React from "react"
import PrismCode from './PrismCode';
import This from '../code/This';
import Code from '../code/Code';

const summary = "";
const introduction = "Over the years, I’ve created, been asked, and acquired a number of interview questions." +
    "So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" +
    "my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: <span>What is the general contract of hashCode?</span>,
        answer: (<span><ul>
            <li>if obj1.equals(obj2) is true then obj1.hashCode() == obj2.hasCode()</li>
            <li>if obj1.equals(obj2) is false then obj1.hashCode() <b>may or may not</b> equal obj2.hasCode()</li>
        </ul>
        </span>)
    },
    {
        question: <span>Given the general contract of hash code, when you are writing equals() method, which other method or methods you need to override?</span>,
        answer: (<span>hashcode() must be overridden. 
        </span>)
    },
    {
        question: <span>What is the difference between <Code>compareTo()</Code> and <Code>equals()</Code>?</span>,
        answer: (<span><ul>
            <li><Code>compareTo()</Code> returns a integer where <Code>equals()</Code> returns a boolean.</li>
            <li>if obj1.equals(obj2) is false then obj1.hashCode() <b>may or may not</b> equal obj2.hasCode()</li>
        </ul>
        </span>)
    },
    {
        question: <span>What should obj1.compareTo(obj2) if two objects are equal (ob1.equals(obj2) is true)?</span>,
        answer: (<span>From the Java documentation: <p>
             <i>The behavior of a set is well-defined even if its ordering is inconsistent with equals; it just fails to obey the general contract of the Set interface.</i>
             </p>
        This means that you must implement compareTo() properly to maintain the contract of the Set interface.  There is no explict contract that states if obj1.equals(obj2) 
        then obj1.compareTo(obj2) == 0.
        </span>)
    },
    {
        question: <span>What is the size of the <Code>Set</Code> given that <Code>TreeSet</Code> uses <Code>compareTo</Code> to determine equality:
        <PrismCode code={'BigDecimal b1 = new BigDecimal("2");  '  +'\n' + 
                'BigDecimal b2 = new BigDecimal("2.0");  '  + '\n' +'\n' +
                'System.out.println(b1.compareTo(b2));  // 0  '  + '\n' +
                'System.out.println(b1.equals(b2));  // false  '  + '\n' +'\n' +                
                'Set<BigDecimal> s = new TreeSet<>();    '  + '\n' +
                's.add(b1);  '  + '\n' +
                's.add(b2);  '  + '\n' +
                'System.out.println(s.size());  '
        } language="java" />
        </span>,
        answer: (<span>The size is 1.
        </span>)
    },
    {
        question: <span>How does <Code>Map#get</Code> make use of a object's <Code>equal</Code> and <Code>hashCode</Code> methods?</span>,
        answer: (<span><ul>
            <li><Code>hashcode()</Code> is used to determine which bucket in which the value is stored.</li>
            <li>If the bucket contains more than one object, <Code>equals</Code> is used to return the correct key and it's respective value.</li>
        </ul>
        </span>)
    },
];

export { questions, summary, introduction };