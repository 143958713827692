import React from 'react';
import URL from '../../constants/routes';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div>
      <div className="row" >
        <div className="col-3">
        </div>
        <div className="col-sm-10 col-md-6">
          <div className="heading">Are you into to Blender?</div>
          <p>
             Checkout our videos and articles for inspirational tutorials and walkthroughs to jumpstart your own projects.
				  </p>


        </div>
        <div className="col-6">
        </div>
      </div>


      <div className="row">
        <div className="col">
        </div>
        <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">

          <div className="heading">Featured Videos</div>
          <div className="sub-heading sub-heading-featured">Create Amazing Strawberries in Blender</div>

          <table>
            <tbody>
              <tr className="video-table">
                <td>
                  <div className="thumb-wrapper">
                    <a href="https://www.youtube.com/watch?v=7TkAX9_bv9o" target="_blank">
                      <img className="video-thumb" src="https://canosielabs.com/img/articles/3d/sb/thumbnail-twitter.png"></img>
                    </a><br />
                  </div>
                </td>
                <td>

                </td>
                <td>

                </td>
              </tr>
            </tbody>
          </table>

          <br /><br />

          <div className="sub-heading sub-heading-featured">Blender Food Tutorials</div>

          <table>
          <tbody>
            <tr className="video-table">
              <td>
                <div className="thumb-wrapper">
                  <a href="https://www.youtube.com/watch?v=Mb7AJz7pcw8" target="_blank">
                    <img className="video-thumb" src="https://canosielabs.com/img/articles/3d/macaron/thumbnail.png"></img>
                  </a><br />
                  <span className="video-thumb-title">Create Photorealistic Foods in Blender: Macaron time!</span>
                </div>
              </td>
              <td>
                <div className="thumb-wrapper">
                  <a href="https://www.youtube.com/watch?v=UclX2X9bbrQ" target="_blank">
                    <img className="video-thumb" src="https://canosielabs.com/img/articles/3d/rb/thumbnail.png"></img>
                  </a>
                  <br />
                  <span className="video-thumb-title">Making a Raspberry in Blender</span>
                </div>
              </td>
              <td>

              </td>
            </tr>
            </tbody>
          </table>

          <br /><br />
          <div className="sub-heading sub-heading-featured">More Blender Videos</div>
          <table>
          <tbody>
            <tr className="video-table">
              <td>
                <div className="thumb-wrapper">
                  <a href="https://www.canosielabs.com/blog/kiwifruit-using-blender/" target="_blank">
                    <img className="video-thumb-triple" src="https://canosielabs.com/img/articles/3d/kiwi/thumbnail1b.png"></img>
                  </a><br />
                  <span className="video-thumb-title">Kiwi fruit in Blender (basic shape)</span>
                </div>
              </td>
              <td>
                <div className="thumb-wrapper">
                  <a href="https://www.youtube.com/watch?v=SOFgWk-jAMU" target="_blank">
                    <img className="video-thumb-triple" src="https://canosielabs.com/img/articles/3d/kiwi/thumbnail4.png"></img>
                  </a>
                  <br />
                  <span className="video-thumb-title">Finishing our kiwi fruit in Blender</span>
                </div>
              </td>
                   <td>
                <div className="thumb-wrapper">
                  <a href="https://www.youtube.com/watch?v=VPgYETga0T0" target="_blank">
                    <img className="video-thumb-triple" src="https://canosielabs.com/img/articles/3d/kiwi/thumbnail3.png"></img>
                  </a>
                  <br />
                  <span className="video-thumb-title">Make a kiwi in Substance Designer</span>
                </div>
              </td>
            </tr>
            <tr className="video-table">
              <td>
              <div className="thumb-wrapper">
                  <a href="https://www.youtube.com/watch?v=Mb7AJz7pcw8" target="_blank">
                    <img className="video-thumb-triple" src="https://canosielabs.com/img/articles/3d/macaron/thumbnail.png"></img>
                  </a><br />
                  <span className="video-thumb-title">Create Photorealistic Foods in Blender: Macaron time!</span>
                </div>
              </td>
              <td>
              <div className="thumb-wrapper">
                  <a href="https://www.youtube.com/watch?v=UclX2X9bbrQ" target="_blank">
                    <img className="video-thumb-triple" src="https://canosielabs.com/img/articles/3d/rb/thumbnail.png"></img>
                  </a>
                  <br />
                  <span className="video-thumb-title">Making a Raspberry in Blender</span>
                </div>
              </td>
              <td className="td-more">
                <a href="https://www.youtube.com/c/CanosieLabs/videos" target="_blank">
                  +5 more
                </a>
              </td>
            </tr>
            </tbody>
          </table>
 





        </div>
        <div className="col">
        </div>
      </div>
      <div className="row">
        <div className="col-3">
        </div>
        <div className="col-sm-10 col-md-6">
          <div className="social-media-links">
            <a href="https://twitter.com/canosielabs" target="_blank"><img src="https://canosielabs.com/img/twitter.png"
              className="social-media-link" /></a>
            <a href="https://www.instagram.com/canosielabs/" target="_blank"><img src="https://canosielabs.com/img/instagram.png"
              className="social-media-link" /></a>
          </div>
        </div>
        <div className="col-3">
        </div>
      </div>
    </div>
  );
};

LandingPage.propTypes = {
};

export default LandingPage;
