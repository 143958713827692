import React, { Component } from 'react';

import withRouter from './withRouter'
import './NavBar.scss';
import URL from '../../../constants/routes';

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {};

    }



    handleLinkClick(location) {
        this.props.history.push(location)
    }

    handleExternalLinkClick(location) {
        window.location.href = location;
    }

    render() {

        const { location } = this.props;

        const homeClassNames = location?.pathname === URL.LANDING ? "nav-link nav-button active" : "nav-link nav-button";
        const articlesClassNames = location?.pathname === URL.ARTICLES ? "nav-link nav-button active" : "nav-link nav-button";
        return (
            <nav className="navbar navbar-expand-md navbar-light bg-light">

                <div className="container-fluid">
                    <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <button className="nav-button main-logo-collapsed-button" onClick={() => this.handleLinkClick(URL.LANDING)}>
                        <img className="main-logo-collapsed" src="https://canosielabs.com/canosie_logo.png" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item navbar-div">
                                <button className="nav-button" onClick={() => this.handleLinkClick(URL.LANDING)}><img className="main-logo " src="https://canosielabs.com/canosie_logo.png" /></button>

                            </li>
                        </ul>
                    </div>

                    <div className="collapse navbar-collapse mx-auto " id="navbarNav">
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <button className={homeClassNames} onClick={() => this.handleLinkClick(URL.LANDING)}>Home</button>
                            </li>
             
                            <li>
                                <button className={articlesClassNames} onClick={() => this.handleExternalLinkClick('https://canosielabs.com/blog')}>Videos and Blog</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
};

NavBar.propTypes = {};

export default withRouter(NavBar);
