import { LeetSearchResultType } from '../../service/ApiService';
interface SearchRowProps {
    question: LeetSearchResultType;
}
const SearchResultRow = ({question}: SearchRowProps):JSX.Element  => {

   
    return (
        <tr>
            <td>{question.questionFrontendId}</td>
            <td>{question.title}</td>
            <td>{question.difficulty}</td>
            <td>{question.frequency}</td>
        </tr>
    );
};

export default SearchResultRow;