import React from 'react';
import Code from '../../../component/code/Code';
import PrismCode from '../../../component/interview-questions/PrismCode';

const InterviewPrepFullstack = () => {

    return (
        <React.Fragment>
            <div className="row">
                <div className="col"></div>
                <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
                    <img className="featured-image rounded mx-auto d-block" src="https://canosielabs.com/img/articles/lambda/lambda-interview-feature.png" />
                </div>

                <div className="col"></div>
            </div>
            <div className="row first-row">
                <div className="col"></div>
                <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6 blog-title">
                    <h1>Fullstack Enviornment Setup</h1>
                </div>

                <div className="col"></div>
            </div>
            <div className="row hide-sources-row">
                <div className="col"></div>
                <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
                    In this section, I will show you a fullstack developer setup in preparation for an interview using React
                    as the front end and Springboot(Java) as the backend.
                </div>
                <div className="col"></div>
            </div>
            <div className="row">
                <div className="col"></div>
                <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
                    <h2>Backend Enviornment Setup</h2>
                    <h4>Package Setup</h4>
                    Create the following package structure:
                    <ul>
                        <li>com.org - the application class will reside here.</li>
                        <li>com.org.service</li>
                        <li>com.org.service.model - optional, but I put any service layer modal objects</li>
                        <li>com.org.controller</li>
                        <li>com.org.error - optional, but I usually but a <Code>BaseException extends RuntimeException</Code> and other error handling logic here</li>
                        <li>com.org.util - another optional package for util classes</li>
                        <li>com.org.web - for <Code>ApiRoutes.java</Code></li>
                        <li>com.org.web.model - for any web modal objects</li>
                    </ul>

                    <h4>Springboot Application Class</h4>
                    <ul>
                        <li>Make sure the <Code>@SpringBootApplication</Code> class annotation is used.  This is composed of the <Code>@EnableAutoConfiguration</Code>
                        and <Code>@ComponentScan</Code>, among others. </li>
                    </ul>
                    <PrismCode code={'package com.org;'  + '\n' + '\n' +
                    '@SpringBootApplication  '  + '\n' + 
                        'public class DemoApplication {  '  + '\n' + 
                        '	public static void main(String[] args) {  '  +'\n' +  
                        '		SpringApplication.run(DemoApplication.class, args);  '  + '\n' + 
                        '	}  '  + '\n' + 
                        '}  '} language="java" />
                    <br />
                    <h4>Default Controller</h4>
                    <p>
                        Make sure the default controller has the <Code>@RestController</Code> annotation. This annotation includes the <Code>@ResponseBody</Code> so that
                        the returned objects from controllers are searlized into JSON.  </p>
                        <p>The <Code>@Autowired</Code> annotation should be used to
                        load the default service.  It can also annotated using the <Code>@Qualifier("beanName")</Code> if there are more than one beans of the same type.
                    </p>
                    <p>
                        Controller methods must have one of the following methods to map a request to the method by Spring's <Code>DispatchServlet</Code>.
                        <ul>
                            <li>GetMapping</li>
                            <li>PostMapping</li>
                            <li>PatchMapping</li>
                            <li>etc</li>
                        </ul>
                        Remember, these mapping types are a shortcut for <Code>@RequestMapping(method = RequestMethod.GET)</Code>
                    </p>
                    <PrismCode code={'package com.org.web.controller;  '  + '\n' +
                        '  '  + '\n' +
                        '@RestController  '  + '\n' +
                        'public class ProjectController {  '  + '\n' +
                        '  '  + '\n' +
                        '  @Autowired  '  + '\n' +
                        '  private ProjectService projectService;  '  + '\n' +
                        '  '  + '\n' +
                        '  @GetMapping(value = ApiRoutes.PROJECT)  '  + '\n' +
                        '  public Map<String, String> getProjectData() {  '  + '\n' +
                        '  	return new HashMap<>();  '  + '\n' +
                        '  }  '  + '\n' +
                        '}  '} language="java" />
                </div>
                <div className="col"></div>
            </div>
        </React.Fragment>
    )
};

export default InterviewPrepFullstack;