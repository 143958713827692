const ShowSourceService = (function () {

    let showSourceByDefault = true;

    const setSourceDiplay = (value) => {
        showSourceByDefault = value;
    };

    const getSourceDisplay = () => {
        return showSourceByDefault;
    }

    return {
        setSourceDiplay,
        getSourceDisplay
    };
})();
export default ShowSourceService;