import React from 'react';

const CodeSnip = ({children}) => {

    return(
        <span className="code-snip">
            {children}
        </span>
    )
};

export default CodeSnip;