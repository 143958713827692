import React from 'react';
import InterviewQuestion from '../../../component/interview-questions/InterviewQuestion';
import {questions, summary, introduction} from '../../../component/interview-questions/LambdaQuestions';

const InterviewQuestionsLambda = () => {
    return (<InterviewQuestion
        title="Java Lambda Interview Questions"
        introduction={introduction}
        summary={summary}
        questions={questions} 
        featureImage="https://canosielabs.com/img/articles/lambda/lambda-interview-feature.png"
        />);
};

export default InterviewQuestionsLambda;