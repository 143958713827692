import React, { useState, useEffect } from 'react';
import PrismCode from './PrismCode';
import ANSWER_TYPE from '../../constants/appConstants';

const Question = ({ questionNumber, question, showCode }) => {

    const [isTextAreaHidden, setTextAreaHidden] = useState(true);
    const [isAnswerHidden, setAnswerHidden] = useState(true);

    const writeAnswerText = isTextAreaHidden ? "Write Answer" : "Hide";
    const showAnswerText = isAnswerHidden ? "Show Answer" : "Hide Answer";

    const code = `public class HelloWorld \{
    public static void main(String[] args) \{
        System.out.println(\"Hello World!\")\;
    \}
}`;
    const answerSection = isAnswerHidden ? "answer-section-hide" : "answer-section-show";
    const textArea = !isTextAreaHidden ? <textarea className="answer-textarea"></textarea> : null;
    const data = showCode ? 'showing' : 'notshowing';

    return (<React.Fragment>
        <div className="row">
            <div className="col" ></div>
            <div className={`col-sm-10  col-md-10 col-lg-10 col-xl-6 question-text ${data}`} >
                {questionNumber}. {question.question}
            </div>
            <div className="col"></div>
        </div>
        {
            question.questionType === ANSWER_TYPE.OE && (
                <div className="row">
                    <div className="col"></div>
                    <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
                        <button className="question-button" type="button" onClick={() => setTextAreaHidden(!isTextAreaHidden)} >{writeAnswerText}</button><br />
                        {textArea}
                    </div>
                    <div className="col"></div>
                </div>
            )
        }

        <div className="row answer-row" >
            <div className="col"></div>
            <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
                <button className="question-button" type="button" onClick={() => setAnswerHidden(!isAnswerHidden)} >{showAnswerText}</button><br />

                <div className={answerSection}>
                    {question.answer}

                </div>
            </div>
            <div className="col"></div>
        </div>
    </React.Fragment>)
};

export default Question;