import React from "react"
import ShowSourceService from '../../service/ShowSourceService';

export class PrismCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showCode: true};
    this.ref = React.createRef()
    this.handleCodeOptionChange = this.handleCodeOptionChange.bind(this);
  }

  componentDidMount() {
    this.highlight()
  }
  componentDidUpdate() {
   // this.highlight()
  }

  handleCodeOptionChange() {
    this.setState({showCode: !this.state.showCode});
  }

  highlight() {
  setTimeout(() => {
    console.log(window.Prism)
    window.Prism.highlightAll();
  }, 0)
  }
  render() {
    const {plugins, language } = this.props
    const {showCode} = this.state;
    const code = this.props.code? this.props.code : this.props.children;
    const buttonClass = showCode? 'hide-section code-preview': 'code-preview'
    const codeDisplayText = showCode? 'Hide Code': 'Show Code';
    return (
      <React.Fragment>
        <div><button onClick={this.handleCodeOptionChange} className="showCodeBtn" type="button" >{codeDisplayText}</button></div>
          <div className={buttonClass}>
            <br/>
          </div>
        <div className={!showCode ? 'hide-section code-section': 'code-section'}> 
          <pre>
          <code className={`language-${language} code-style`}>
            {code}
          </code>
        </pre>
      </div>
      </React.Fragment>
    )
  }
}
export default PrismCode;