import React from 'react';

const generateColumnHeader = (columnHeaderData) => {
    const data = columnHeaderData.map((data, index) => {
        return (<td key={index} className="header">{data}</td>);
    })

    if (!columnHeaderData) {
        return null;
    }
    return (
        <tr>
            {data}
        </tr>
    );
};

const MemoizationTable = ({ columnHeaderData, rowHeaderData, data }) => {

    const columnHeader = generateColumnHeader(columnHeaderData);
    const rowdata = data.map((rowData, index) => {

        const rowHeaderTd = rowHeaderData?.length>0 ? (
            <td className="header" >{rowHeaderData[index]}</td>
        ) : null;


        const tds = rowData.map((d, idx)=> {
            if (d==='inv'){
                return (<td className="invalid-entry"key={idx}><div>&nbsp;</div></td>);
            }
            return (<td key={idx}>{d ? d : <div>&nbsp;</div>}</td>);
        });

        return (
            <tr key={index}>
                {rowHeaderTd}
                {tds}
            </tr>
        )
    });

    return (
        <table className="memo-table text-center">
            <tbody>
                {columnHeader}
                {rowdata}
            </tbody>
        </table>
    );
};

export default MemoizationTable;