import React from "react"
import PrismCode from './PrismCode';
import This from '../code/This';
import Code from '../code/Code';

const summary = "";
const introduction = "Over the years, I’ve created, been asked, and acquired a number of interview questions." +
    "So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" +
    "my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: <span>Can you briefly explain what a closure is?</span>,
        answer: (<span>A closure is the combination of a function and the lexical environment (state) within which that function was declared.
             The function has access to the variables from outside the scope of the closure (function) and continue to have access to 
             these variables even when the executing context of the surrounding function ends.
        </span>)
    },
    {
        question: <span>What does it mean when closures are used to maintain data privacy in a object?</span>,
        answer: (<span><p>Closures (anonymous functions) can be used to keep maintain data privacy.  
            Instead of polluting the global scope, variables defined in a closure can only be accessed within the closure.</p>
                    <PrismCode code={'(function () {  '  + '\n' +
                        '  const myVar = “private”;  '  + '\n' +
                        '})();  '+ '\n' +
                         '\n' +
                        'console.log(myVar); // outputs undefined'
        } language="javascript" />
        </span>)
    },
    {
        question: <span>When are closures created?
        </span>,
        answer: (<span>Closures are created every time a function is created.
        </span>)
    },
    {
        question: <span>Whats the difference between blocked scope and function scope variables?
        </span>,
        answer: (<span>
            
            <p>
                       <b>var</b> is function scoped.  When it is declared, it is scoped to the function it is decarled in -
            Varibles declared as var in a "if" statement are avialiable outside the "if" statement since it is scoped
            to the parent function it belongs to.  Also remember that <b>var</b> might be hoisted to the top of the function.
            </p>
            <PrismCode code={'console.log(myVar); // outputs undefined' + '\n' +
                         '\n' +
                        'if (something == true) {'  + '\n' +
                        '  var myVar = \"private\";  '  + '\n' +
                        '};  '+ '\n' +
                         '\n' +
                        'console.log(myVar); // outputs \"private\"'
        } language="javascript" />

            <p>
                Block scoped variables are only scoped to the block they are declared in.  In the "if" example used above,
                any variable declared as a let/const in a "if" statement are not visiable outside it. 

            </p>
            <PrismCode code={'console.log(myVar); // outputs Uncaught ReferenceError: myVar is not defined' + '\n' +
                         '\n' +
                        'if (something == true) {'  + '\n' +
                        '  let myVar = \"private\";  '  + '\n' +
                        '};  '+ '\n' +
                         '\n' +
                        'console.log(myVar); // outputs outputs Uncaught ReferenceError: myVar is not defined'
        } language="javascript" />
        </span>)
    },
    {
        question: <span>In this code, what does the output of the func() call produce?
            <PrismCode code={  'let b = \'abc\';  '  + '\n' +
                'const func = () =>  console.log(b);  '  + '\n' +
                'b = \'zzz\';  '  + '\n' +
                'func();  '
        } language="javascript" />
        </span>,
        answer: (<span>
            The output is <Code>zzz</Code> even though the value of <Code>b</Code> was <Code>aaa</Code>
            when the function was created.  This is because closures reference variables, not values that 
            the variables have at the time the closure is created.
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?
            <PrismCode code={ 'const arr = [];  '  +  '\n' +
                    'for (var i=1; i<4; i++) {   '  +  '\n' +
                    '  arr[i] = (function(n) {  '  +  '\n' +
                    '    return i+n;  '  +  '\n' +
                    '  });  '  +  '\n' +
                    '}  '  +  '\n' + '\n' +
                    'console.log(arr[1](7));  '  +  '\n' +
                    'console.log(arr[2](7));  '  +  '\n' +
                    'console.log(arr[3](7));  ' 
        } language="javascript" />
        </span>,
        answer: (<span><p>The output 11 is outputted all 3 times.  Note that i is a variable that is declared outside the closure,
            but inherited when the closure is created. When the closure is created, the 
            value of the variable i is 1, 2, 3 but the closure references the variable i, not the value.  
            As a result, when the closure is invoked, the value of the variable i is 4.</p>
            <p>
                Variables declared outside the scope are referenced in a special internal 
                object of the function [[scope]].  Therefore they do not get garbage collected even when the outer method ends.
            </p>
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?  Note, this is similar to 
            the previous question, but the <Code>i</Code> is defined as a <Code>let</Code> instead of a <Code>var</Code>.
            <PrismCode code={ 'const arr = [];  '  +  '\n' +
                    'for (let i=1; i<4; i++) {   '  +  '\n' +
                    '  arr[i] = (function(n) {  '  +  '\n' +
                    '    return i+n;  '  +  '\n' +
                    '  });  '  +  '\n' +
                    '}  '  +  '\n' + '\n' +
                    'console.log(arr[1](7));  '  +  '\n' +
                    'console.log(arr[2](7));  '  +  '\n' +
                    'console.log(arr[3](7));  ' 
        } language="javascript" />
        </span>,
        answer: (<span><p>The output is 8, 9, 10
            <Code>let</Code> allows you to declare variables that are limited to the scope of a block statement.  When we used <Code>let</Code>
            to declare the variable <Code>i</Code>, we're using a different 'i' for each iteration for the loop - however, the counter is not
            reset to 0 due to the mechanics of the for loop construct. 
            </p>
            <p>
                This is why the output is 8, 9, 10 and not 11, 11, 11.  Each closure is closing around a different <Code>i</Code> variable which
                has its value set once and does not change.
            </p>
        </span>)
    },
    {
        question: <span>Does a closure have reference to a variabe declared in the parent scope once the parent scope ends? 
        </span>,
        answer: (<span>
            <p>Closures have access to the outer function’s variable even after the outer function returns because 
                closures store references to the outer function’s variables.
            </p>
        </span>)
    },
    {
        question: <span>Normally, in the global scope:
                    <PrismCode code={ 'var myVar1 = 1;  '  +  '\n' +
                        'var myVar2 = myVar1;  '  +  '\n' +
                        'console.log(myVar1) // 1  '  +  '\n' +
                        'console.log(myVar2) // 1  '  +  '\n' +
                        'myVar1 = 44;  '  +  '\n' +
                        'console.log(myVar1) // 44  '  +  '\n' +
                        'console.log(myVar2) // 1  '
        } language="javascript" />
        This is because these primitive values are copied they are assigned to another variable.
        If we do the same in our closure example, it won’t work:
        <PrismCode code={ 'const arr = [];  '  +  '\n' +
                    'for (let i=1; i<4; i++) {   '  +  '\n' +
                    '  arr[i] = (function(n) {  '  +  '\n' +
                    '    return i+n;  '  +  '\n' +
                    '  });  '  +  '\n' +
                    '}  '  +  '\n' + '\n' +
                    'console.log(arr[1](7));  // outputs 11'  +  '\n' +
                    'console.log(arr[2](7));  // outputs 11'  +  '\n' +
                    'console.log(arr[3](7));  // outputs 11 ' 
        } language="javascript" />
        Why and what can we do to fix it?  Ie "fix" the value of <Code>i</Code>.
        </span>,
        answer: (<span>
            <p>It’s because the variable I is resolved when the closure is invoked, and <Code>i</Code> has a value of 4. 
               Therefore 4 is copied and assigned to f, giving us the same result as before.</p><p>
               What we really need to do is “fix” the value of <Code>i</Code> when we create the closure.  
               Do this we wrap the creation of the closure in self-executing function (closure) which takes 1 parameter (i).  </p>
               <PrismCode code={ 'var fns = [];  '  +  '\n' +
                    'for (var i=1; i<4; i++) {   '  +  '\n' +
                    '  (function(copyVar){  '  +  '\n' +
                    '    fns[i] = (function(n) {  '  +  '\n' +
                    '      return copyVar+n;  '  +  '\n' +
                    '    });  '  +  '\n' +
                    '  )(i);  '  +  '\n' +
                    '}'  
                 } language="javascript" />
               <p>
               When the function executes, it passes the value of <Code>i</Code> by value, copying it.  Therefore, within the closure, 
               the variable <Code>copyVar</Code> is not tied to the variable <Code>i</Code> anymore. 
                Instead, <Code>copyVar</Code> is declared by the self-executing function, 
                inehreited by the closure, and “fixed’ to whatever value <Code>i</Code> was when the self-invoking function executed.
            </p>
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?
            <PrismCode code={'var text = \'aaa\';  '  + '\n' +
            'function logIt(){  '  + '\n' +
            '  console.log(text);  '  + '\n' +
            '  var text = \'bbb\';  '  + '\n' +
            '};  '  + '\n' +
            'logIt();  '
        } language="javascript" />
        </span>,
        answer: (<span>The output is <Code>undfined</Code> because the declaration of <Code>text</Code> inside <Code>logIt</Code> is hoisted
        to the top of the if-statement scope but the assignment stays where it is.  Thus when the function is invoked, the variable <Code>text</Code>does
        not have a value yet.  While it originally does inherit the <Code>text</Code> variable from the parent scope, since it is redeclared,the value is 
        <Code>undefined</Code>.
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?
            <PrismCode code={'var a = {  b: 3 };  '  + '\n' +
                '\n' +
                'a.func = function() {  '  + '\n' +
                '  return function(n) {  '  + '\n' +
                '    console.log(this);  '  + '\n' +
                '    return this.b + n;  '  + '\n' +
                '  }  '  + '\n' +
                '};  '+ '\n' +'\n' +
                'a.func()(1);'
        } language="javascript" />
        </span>,
        answer: (<span>The output is <Code>window</Code> followed by <Code>NaN</Code>.  The tricky part is that <Code>a.func()</Code> is a function that returns a function. 
        This function returned takes one argument and we invoke with the number 1.  When invoked, we create a functional execution context, and <This/>
        is assigned to the window since the function is not being invoked using a object reference.  
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?
            <PrismCode code={'console.log((function(x) {  '  + '\n' +
                            '  return (function(y) {  '  + '\n' +
                            '      return x;  '  + '\n' +
                            '  })(2)  '  + '\n' +
                            '})(1));  '
        } language="javascript" />
        </span>,
        answer: (<span><p>Let's walk though this.  It is a self invoking function being invoked with the argument <Code>1</Code>.</p>
        <p>It returns the return value of yet another self invoking function with the argument <Code>2</Code>.</p>
        <p>In the inner most function, it has just been invoked with the value of 2.  It is a closure that closes over the variable <Code>x</Code>
        from the outer-scope.  The outer function was invoked with arugment value of 1, thus <Code>x</Code> has the value of 1 in the outer closure and the
        inner most closure.  Thus the output is 1.  The inner most self invoking function paremeter <Code>y</Code> is not being used and does not effect the outcome.</p>
    
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?
            <PrismCode code={ '   const arr = [10, 12, 15, 21];  '  + '\n' +
                        '   for (var i = 0; i < arr.length; i++) {  '  + '\n' +
                        '     setTimeout(function() {  '  + '\n' +
                        '       console.log(\'The index of this number is: \' + arr[i]);  '  + '\n' +
                        '     }, 3000);  '  + '\n' +
                        '   }  '
        } language="javascript" />
        </span>,
        answer: (<span><p>The var is function scoped so this syntax creates a new binding same variable i each iteration.  Thus, the output
        is 21, 21, 21, 21.</p>    
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?
            <PrismCode code={ 'const arr = [10, 12, 15, 21];  '  + '\n' +
                        'for (let i = 0; i < arr.length; i++) {  '  + '\n' +
                        '  setTimeout(function() {  '  + '\n' +
                        '    console.log(\'The index of this number is: \' + arr[i]);  '  + '\n' +
                        '  }, 3000);  '  + '\n' +
                        '}  '
        } language="javascript" />
        </span>,
        answer: (<span><p>The ES6 <Code>let</Code> is block scoped so this syntax creates a new binding to <Code>i</Code> for each closure created in the loop.  Thus, the output
        is 10, 12, 15, 21.</p>    
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?
            <PrismCode code={'function makeFunc() {  '  + '\n' +
                        '  var firstName = \'test\';  '  + '\n' +'\n' +
                        '  function displayName() {  '  + '\n' +
                        '    console.log(firstName);  '  + '\n' +
                        '  }  '  + '\n' +
                        '  return displayName;  '  + '\n' +
                        '}  '  + '\n' +'\n' +
                        'var myFunc = makeFunc();  '  + '\n' +
                        'myFunc();  ' 
        } language="javascript" />
        </span>,
        answer: (<span><p>The output is <Code>test</Code> since closure <Code>displayName</Code> still has access to the <Code>firstName</Code> variable
        even after the <Code>makeFunc</Code> function's socpe ends.</p>    
        </span>)
    },
    {
        question: <span>What is the output from the statement and why?  What happens if you try to invoke <code>SampleService.doSomethingElse</code>
            <PrismCode code={'type SampleService = {  '  + '\n' +
                        '  doSomething: (url: string) => string;  '  + '\n'  +
                        '};  '  + '\n' +'\n' +
                        'const SampleService = (() :SampleService => {'  + '\n' +
                        '  const doSomethingElse = (url: string): string => {'  + '\n' +
                        '    console.log(url);'  + '\n' +
                        '    return "abc";'  + '\n' +
                        '  }'  + '\n' +
                        ''  + '\n' +
                        '  const doSomething = (url: string): string => {'  + '\n' +
                        '    console.log(doSomethingElse(url));'  + '\n' +
                        '    return "done";'  + '\n' +
                        '  };'  + '\n' +'\n' +
                        '  return {'  + '\n' +
                        '    doSomething,'  + '\n' +
                        '  };'  + '\n' +
                        '})();'  + '\n' +'\n' +
                        'export default SampleService;'  + '\n' +'\n'  +'\n' +
                        '// in some other file'  + '\n' +
                        'console.log(SampleService.doSomething("google.com"));' 
        } language="typescript" />
        </span>,
        answer: (<span><p>The output is <Code>google.com</Code> then <Code>abc</Code> then <Code>done</Code>.  Invoking  <code>SampleService.doSomethingElse</code>
        will error since  <code>SampleService.doSomethingElse</code> is not part of the object reutnred by the IIFE.</p>    
        </span>)
    }
];

export { questions, summary, introduction };