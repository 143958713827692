import React from 'react';
import LeetQuestion from './LeetQuestion';
import { MathJax } from "better-react-mathjax";
import TransitionFunction from './TransitionFunction';
import InlinePrismCode from '../../../../component/interview-questions/InlinePrismCode';
import CodeSnip from '../CodeSnip';
import Code from '../../../../component/code/Code';
import MemoizationTable from './MemoizationTable';
const Lcs = () => {
    const qd = (
        () => {
            return (<>
                <p>
                    Given two strings text1 and text2, return the length of their longest common subsequence. If there is no common subsequence, return 0.
                </p>
                <p>
                    A subsequence of a string is a new string generated from the original string with some characters (can be none) deleted without changing the relative order of the remaining characters.
                </p>
                <p>
                    For example, "ace" is a subsequence of "abcde".
                </p>
                <p>
                    A common subsequence of two strings is a subsequence that is common to both strings.
                </p>
            </>)
        }
    );

    const examples = [
        {
            inputs: "text1 = \"abcde\", text2 = \"ace\"",
            outputs: "3",
            explaination: "The longest common subsequence is \"ace\" and its length is 3."
        },
        {
            inputs: "text1 = \"abc\", text2 = \"abc\"",
            outputs: "3",
            explaination: "The longest common subsequence is \"abc\" and its length is 3."
        },
        {
            inputs: "text1 = \"abc\", text2 = \"def\"",
            outputs: "0",
            explaination: "There is no such common subsequence, so the result is 0."
        },
        {
            inputs: "text1 = \"\", text2 = \"\"",
            outputs: "0",
            explaination: "The common subsequence of two empty strings are empty.",
            custom: true
        },
    ];

    const BruteForce = (
        <>
            <p>
                The brute for solution is to compare each character of each string one at a time from <MathJax inline={true}>{"$0..n$"}</MathJax>.  If the two characters match, then we increase our subsequence count by one
                and find the longest common subsequence for the substrings starting at the next index.  I.e. <b>lcs(n + 1, m + 1) + 1</b>
            </p>
            <p>
                If the characters do not match, then we take the max of text1 without the current characeter or text2 without the current character.
                <MathJax>{String.raw`$$lcs(n, m)=max\begin{cases}lcs(n+1, m) \\lcs(n, m+1) \end{cases}$$`}</MathJax>
            </p>
            This leads to a runtime of <MathJax inline={true}>{"$O(2^{(k)})$"}</MathJax> where <b>k</b> is the min of the length of text1 or text2.
        </>
    )

    const OverlappingSubproblems = (
        <>Yes.  There will be multiple calls to calculate the longest common subsequence of a two strings at length n and m depends on the
            longest common subsequence of two strings at length (n-1, m-1) or (n, m-1) or (n-1, m)</>
    );


    const Intuition = (
        <>
            <p>
                Our brute force solution actually leads us into our optimal solution using dynamic programing or memoization.
            </p>
            <p>
                Notice that when the last character of the two strings match,
                then the longest common subsquence is 1 plus the longest common subsequence without the characters.
                I.e. the <CodeSnip>0...(i-1)</CodeSnip> characters of <CodeSnip>text1</CodeSnip> and <CodeSnip>0..(j-1)</CodeSnip> characters of <CodeSnip>text2</CodeSnip>.
            </p>
            <p>
                For example, say <CodeSnip>text1="GXTXAYB" text2="AGGTAB"</CodeSnip> and we are looking at character <CodeSnip>i=4</CodeSnip>
                on <CodeSnip>text1</CodeSnip> and <CodeSnip>j=6</CodeSnip> on <CodeSnip>text2</CodeSnip>.
                Since they are the same, the longest common subsequence is
                one plus the longest common sequence from the remaining characters of the inputs.  That is, <CodeSnip>text1="GXTXAY"</CodeSnip> and <CodeSnip>text2="AGGTA"</CodeSnip>.
            </p>
            <p>
                If the characters do not match, then we do not which (if either) of the two characters to include right now. So we have consider both situations where:
            </p>

            <ol>
                <li> We compute the LCS of <CodeSnip>text1</CodeSnip> with the last character removed and <CodeSnip>text2</CodeSnip></li>
                <li> We compute the LCS of <CodeSnip>text1</CodeSnip> and <CodeSnip>text2</CodeSnip> with the last character removed</li>
            </ol>
            <p>
                and take the max of those two calculations.
                I.e. Say <CodeSnip>text1="GXTXAY" text2="AGGTA"</CodeSnip> and were lookg at character <CodeSnip>i=5(Y)</CodeSnip>
                and <CodeSnip>j=4(A)</CodeSnip>.  Since they do not match, we need to take the max of the LCS
                of <CodeSnip>text1="GXTXA"</CodeSnip> and <CodeSnip>text2="AGGTA"</CodeSnip> OR <CodeSnip>text1="GXTXAY"</CodeSnip>  and <CodeSnip>text2="AGGT"</CodeSnip> .
            </p>
            <p>
                We can see from above that the LCS for the two inputs ending at index <CodeSnip>i and j</CodeSnip> require the computation
                of subproblems of the original problem.  We can now start thinking of caching the two variables that are changing, the length of the strings and what to store
                to make the calculations faster - such as LCS for a particular pair of strings.  This gives us:
            </p>
            <p className="text-center">
                <CodeSnip>dp[i][j] = the longest common subsequence for text1 from 0..i and text2 from 0..j</CodeSnip>
            </p>

        </>
    );
    
    const memoDataEmpty = [       
        [0, 0, 0, 0, 0, 0, 0],
        [0, "", "", "", "", "", ""],
        [0, "", "", "", "", "", ""],
        [0, "", "", "", "", "", ""],
        [0, "", "", "", "", "", ""],
        [0, "", "", "", "", "", ""],
        [0, "", "", "", "", "", ""],
        [0, "", "", "", "", "", ""]
    ]

    const rowHeaderData = ["", "G", "X", "T", "X", "A", "Y", "B"];
    const columnHeaderData = ["i/j", "", "A", "G", "G", "T", "A", "B"];
    const MemoTable = <MemoizationTable  rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={memoDataEmpty} />
    
    const BottomUp = (
        <>
            <p>
                Lets consider an example: <CodeSnip>text1="GXTXAYB" text2="AGGTAB"</CodeSnip> again.
            </p>
            <p>
                To fill the <CodeSnip>dp[i][j]</CodeSnip> table in a bottom up fashion, first, in this situation it does not matter which string is the rows and columns.
                We'll decide that length of text1 is represnted by the rows and text2 is represented by the columns.
            </p>
            <p>
                To avoid index out of bounds when evaluating the first character, we can start our strings at row 1 and column 1 and pre-fill row
                <CodeSnip>i=0</CodeSnip> and column <CodeSnip>j=0</CodeSnip> with 0s.
                This row and column combination represents the situatio where either one of the strings are blank.
            </p>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    {MemoTable}
                </div>
                <div className="col-4"></div>
            </div>
            <p>
                We'll then evaulate one row at a time from 1..n and each column 1..m.  At each cell, if:
            </p>
            <ol>
                <li>if <CodeSnip>text.charAt(i) == text.charAt(j)</CodeSnip>, then <CodeSnip>dp[i][j] = dp[i-1][j-1]</CodeSnip></li>
                <li>if <CodeSnip>text.charAt(i) != text.charAt(j)</CodeSnip>, then <CodeSnip>dp[i][j] = max(dp[i-1][j], dp[i][j-1])</CodeSnip>.
                </li>
            </ol>

        <p>
            As we start at row 1 and work our way across and down, we get the following table result:
        </p>
        </>
    );

    const transitionFunction = (
        <>
            <div className="">
                <div className="example-if">
                    if <CodeSnip>text.charAt(i) == text.charAt(j)</CodeSnip>:
                </div >
                <span className="text-center"> <MathJax >{"$f(i,j)=f(i-1, j-1)$"}</MathJax></span>
                <div className="example-if ">
                    if <CodeSnip>text.charAt(i) != text.charAt(j)</CodeSnip>:
                </div>
                <TransitionFunction statement={String.raw`$$f(i, j)=max\begin{cases}f(i-1, j) \\f(i, j-1) \end{cases}$$`} />
            </div>
        </>
    )

    return <LeetQuestion
        questionDefinition={qd}
        title="1143. Longest Common Subsequence"
        examples={examples}
        transitionFunction={transitionFunction}
        bruteForce={BruteForce}
        overlappingSubproblems={OverlappingSubproblems}
        intuition={Intuition}
        bottomUp={BottomUp}
    />
};

export default Lcs;