import React from 'react';
import LeetQuestion from './component/Dp/LeetQuestion';
import './Leet.scss';

//`\int_0^1 x^2\ dx`
const LeetPage = () => {
    return (

        <div className="leet">
            <LeetQuestion />
        </div>
    );
};
export default LeetPage;