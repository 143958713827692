import React from 'react';
import LeetQuestion from './LeetQuestion';
import { MathJax } from "better-react-mathjax";
import TransitionFunction from './TransitionFunction';
import InlinePrismCode from '../../../../component/interview-questions/InlinePrismCode';
import CodeSnip from '../CodeSnip';
import MemoizationTable from './MemoizationTable';
import Code from '../../../../component/code/Code';

const Lps = () => {

    const qd = (
        () => {
            return (<>
                <p>
                    Given a string <CodeSnip>s</CodeSnip>, return the longest palindromic substring in <CodeSnip>s</CodeSnip>.
                </p>
                <p>
                    A string is said to be <b>palindrome</b> if it remains the same on reading from both ends
                </p>
            </>)
        }
    );

    const examples = [
        {
            inputs: "s = \"babad\"",
            outputs: "bab",
            explaination: "\"aba\" is also a valid answer."
        },
        {
            inputs: "s = \"cbbd\"",
            outputs: "bb",
            explaination: ""
        }
    ];

    const BruteForce = (
        <>
            <p>
                The brute for solution is to generate all substrings of all lengths of <CodeSnip>s</CodeSnip> and check if they are a palindrome.
                Think of the nested for-loop we would require to generate the substrings thus it has a time complexity of
                 <MathJax inline={true}>{"$O(n^{2})$"}</MathJax>.  In addition, for each substring, we'll need to check if it is a palindrome or not.
                This is another  <MathJax inline={true}>{"$O(n)$"}</MathJax> for each substring, giving us a total time complexity 
                <MathJax inline={true}>{"$O(n^{3})$"}</MathJax>
            </p>
        </>
    )

    const OverlappingSubproblems = (
        <>Yes.  There will be multiple calls to calculate the longest common subsequence of a two strings at length n and m depends on the
            longest common subsequence of two strings at length (n-1, m-1) or (n, m-1) or (n-1, m)</>
    );


    const Intuition = (
        <>
            <p>
                Our brute force solution is <MathJax inline={true}>{"$O(n^{3})$"}</MathJax>,  <MathJax inline={true}>{"$O(n^{2})$"}</MathJax> of
                which is required to generate the all subsequences.  It might be possible to determine if a string is a palindrome based some subproblem in 
                <MathJax inline={true}>{"$O(1)$"}</MathJax>.
            </p>
            <p>
                If we have an string and we consider one more string at the end, it will always be a palindrome of length 1.  That is, if the new character 
                is added at index <CodeSnip>j</CodeSnip>, the string <MathJax inline={true}>{"$S_{(j,j)}$"}</MathJax>  is a palindrome of length 1.
            </p>
            <p>
                A string, say <CodeSnip>xabax</CodeSnip> is only a palindrome if the substring without the first and last character <CodeSnip>aba</CodeSnip> 
                is a palindrome.  In otherwords, given a existing string and if we add a new character at index <CodeSnip>j</CodeSnip> , 
                it will only be part of a palindrome if the same 
                character appears in position <CodeSnip>i &#60; j</CodeSnip>  and the string <MathJax inline={true}>{"$S_{(i-1,j-1)}$"}</MathJax> is a palindrome.
            </p>
            <p>
                Thus, if we store for combination of start and end points, <MathJax inline={true}>{"$S_{(i,j)}$"}</MathJax> palindrome, and a new character is
                appended to the string, we can compute in  <MathJax inline={true}>{"$O(1)$"}</MathJax> time if this new string <MathJax inline={true}>{"$S_{(i,j+1)}$"}</MathJax>
                is also a palindrome.
            </p>
            <p>
                We have to be slighly careful how we handle palindromes created by two of the same characters beside each other.  I.e. <CodeSnip>"aa"</CodeSnip>
            </p>
       
        </>
    );

    const memoDataEmpty = [
        ["1", "", "", "", ""],
        ["inv", "1", "", "", ""],
        ["inv", "inv", "1", "", ""],
        ["inv", "inv", "inv", "1", ""],
        ["inv", "inv", "inv", "inv", "1"]
    ];
    
    const memoDataFilled = [
        ["1", "0", "1", "0", "0"],
        ["inv", "1", "0", "1", "0"],
        ["inv", "inv", "1", "0", "0"],
        ["inv", "inv", "inv", "1", "0"],
        ["inv", "inv", "inv", "inv", "1"]
    ]



    const rowHeaderData = ["b", "a", "b", "a", "d"];
    const columnHeaderData = ["i/j", "b", "a", "b", "a", "d"];
    const MemoTable = <MemoizationTable rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={memoDataEmpty} />
    const FilledTable = <MemoizationTable rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={memoDataFilled} />
    const BottomUp = (
        <>
            <p>
                Lets consider an example: <CodeSnip>s = \"babad\"</CodeSnip>.
            </p>
            <p>
                The DP table will be represented by a 2D table where <CodeSnip>dp[i][j] = true if </CodeSnip> <MathJax inline={true}>{"$S_{(i,j)}$"}</MathJax> 
                 is a palindrome.  Since <CodeSnip>i &#60;= j</CodeSnip>, the bottom half of the dp table are invalid entries.
            </p>
            <p>
                First, we want to fill the two base cases.   Set <CodeSnip>dp[i][j] = true when i==j</CodeSnip> and 
                <CodeSnip>dp[i][j] = true when j==i+1 and s.charAt(i)==s.chartAt(i+1)==s.chartAt(j)</CodeSnip>          
            </p>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    {MemoTable}
                </div>
                <div className="col-4"></div>
            </div>
            <p>
                We'll then evaulate one row at a time from 1..n and each column 1..m.  At each cell, if:
            </p>
            <ol>
                <li>if <CodeSnip>text.charAt(i) == text.charAt(j)</CodeSnip> and <CodeSnip>dp[i-1][j-1]==true</CodeSnip>, then set dp[i][j] = true</li>
                <li>if <CodeSnip>text.charAt(i) == text.charAt(j)</CodeSnip> and <CodeSnip>j==i+1</CodeSnip>, then set dp[i][j] = true</li>
                <li>otherwise, set <CodeSnip>dp[i][j] = false</CodeSnip></li>
            </ol>

            <p>
                As we start at row 1 and work our way across and down, we get the following table result:
            </p>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    {FilledTable}
                </div>
                <div className="col-4"></div>
            </div>
            <p>
                Once the table is filled, we can go though it one last time (from top to bottom, left to right) to find generate the longest palindromic substring. 
                Using our example, the longest palindromic string is <MathJax inline={true}>{"$S_{(0,2)}$"}</MathJax>, a length of 3.
            </p>
            <p>
                The final runtime complexity is: base cases + filling out the table + finding the longest substring:

            </p>
            <div className="text-center">
            <MathJax>{"$O(n) + O(n^2) + O(n^2) = O(n^2)$"}</MathJax>
            </div>
        </>
    );

    const transitionFunction = (
        <>
            <div className="">
            
              
                <TransitionFunction statement={String.raw`$$f(i, j)=max\begin{cases}
                true, & i==j \mspace{5mu} (base\mspace{5mu}case)
                \\true, & j==i+1 \mspace{5mu} and \mspace{5mu}  S_{(i)}==S_{(j)} \mspace{5mu} ( handles \mspace{5mu} case \mspace{5mu} when \mspace{5mu} two \mspace{5mu} same \mspace{5mu}characters \mspace{5mu}are \mspace{5mu}beside\mspace{5mu} each \mspace{5mu}other. )
                \\true, &if S_{(i)}==S_{(j)} \mspace{5mu} and  \mspace{5mu} S_{(i-1,j-1)} \mspace{5mu} is \mspace{5mu} a \mspace{5mu} palindrome 
                \\false, &otherwise \end{cases}$$`} />
    
            </div>
        </>
    )

    return <LeetQuestion
        questionDefinition={qd}
        title="5. Longest Palindromic Substring"
        examples={examples}
        transitionFunction={transitionFunction}
        bruteForce={BruteForce}
        overlappingSubproblems={OverlappingSubproblems}
        intuition={Intuition}
        bottomUp={BottomUp}
    />
};
export default Lps;