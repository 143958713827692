import React from 'react';

const Cs = ({children}) => {

    return(
        <span className="code-snip">
            {children}
        </span>
    )
};

export default Cs;