import React from 'react';
import LeetQuestion from './LeetQuestion';
import { MathJax } from "better-react-mathjax";
import TransitionFunction from './TransitionFunction';
import InlinePrismCode from '../../../../component/interview-questions/InlinePrismCode';
import CodeSnip from '../CodeSnip';
import Mf from '../Mf';
import Mfi from '../Mfi';
import Cs from '../Cs';

import MemoizationTable from './MemoizationTable';


const MaxSubarray = () => {
    const qd = (
        () => {
            return (<>
                <p>
                Given an integer array <Cs>nums</Cs>, find the subarray which has the largest sum and return its sum.
                </p>
            </>)
        }
    );

    const examples = [
        {
            inputs: "nums = [-2,1,-3,4,-1,2,1,-5,4]",
            outputs: "6",
            explaination: "[4,-1,2,1] has the largest sum = 6."
        },
        {
            inputs: "nums = [1]",
            outputs: "1",
        },
        {
            inputs: "nums = [5,4,-1,7,8]",
            outputs: "23",
        }
    ];

    const BruteForce = (
        <>
            <p>
                TODO
            </p>
        </>
    )

    const OverlappingSubproblems = (
        <></>
    );


    const Intuition = (
        <>
            <p>
                We don't need to return the actual elements of the subarray.  
            </p>
            <p>
                A solution with all positive numbers, then we can take the entire array.  
            </p>
            <p>
                Thus, negative numbers in the solution may cause us to abandon a existing subarray, stopping at a negative number 
                and start from scratch at the next positve number.
            </p>
            <p>
                In order to start a new subarray at <Cs>arr[i]</Cs>, the value of this negative number at position<Cs>arr[i-1]</Cs>
                plus the previous sum, sum(<Mfi>{"$arr_{k..i-1}$"}</Mfi>) for some <Cs>k&le;i</Cs> must be less than <Cs>arr[i]</Cs>.  If this 
                is not the case, than we're better off including this negative number, <Cs>arr[i-1]</Cs> in our subarray, giving us a subarray of 
                <Mfi>{"$arr_{k..i}$"}</Mfi>
            </p>
            <p>
                This gives us two options for any number, as we move consider each point on the array from <Mfi>{"$0..n$"}</Mfi>:                
            </p>
            <ol>
                <li>Use <Cs>arr[i]</Cs> in our subarray. </li>
                <li>Don't use <Cs>arr[i]</Cs> and instead start a new subarray at index <Cs>i</Cs></li>
            </ol>
            <p>
                How do we calculate the value of the subarray ending at <Mfi>{"$arr_{0..i}$"}</Mfi> in a efficient manner?
            </p>
            <p>
                If for every index, <Cs>i</Cs>, we keep track of the largest sum of a subarray that includes <Cs>arr[i]</Cs>, then
                the largest sum of a subarray that ends at <Mfi>{"$arr_{0..i}$"}</Mfi> is at is maximum of <Mfi>{"$arr_{0..i-1} + arr[i]$"}</Mfi> OR
                <Cs>arr[i]</Cs>.  
            </p>
        </>
    );

    const memoDataEmpty:string[][] = [
        ["-2", "1", "-2", "4", "3", "5", "6", "1", "5"]
    ]
  
    const rowHeaderData: string[] = [];
    const columnHeaderData = ["-2", "1", "-3", "4", "-1", "2", "1", "-5", "4"];
    const MemoTable = <MemoizationTable rowHeaderData={rowHeaderData} columnHeaderData={columnHeaderData} data={memoDataEmpty} />

    const BottomUp = (
        <>
        <p>
            Compute from left to right:
        </p>
        {MemoTable}
        <p>
            Store or iterate though the table one more time to find the max value.
            Runtime complexity of <Mfi>{"$O(n)$"}</Mfi>.
        </p>
        </>
    );

    const transitionFunction = (
        <>
            <div className="">
                <TransitionFunction statement={String.raw`$$f(i)=max\begin{cases} f(i-1) + arr[i] 
                \\ arr[i]  \end{cases}$$`} />
            </div>
        </>
    )

    return <LeetQuestion
        questionDefinition={qd}
        title="139. Word Break"
        examples={examples}
        transitionFunction={transitionFunction}
        bruteForce={BruteForce}
        overlappingSubproblems={OverlappingSubproblems}
        intuition={Intuition}
        bottomUp={BottomUp}
    />
};

export default MaxSubarray;