import React from 'react';

const Example = ({ inputs, outputs, explaination }) => {

    return (
        <div className="example">
            <div><span className="ex-heading">Input:</span> {inputs}</div>
            <div><span className="ex-heading">Output:</span> {outputs}</div>
            <div><span className="ex-heading">Explanation:</span> {explaination}</div>
        </div>
    )
};

export default Example;