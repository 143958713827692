import React from 'react';

const ArticlesPage = () => {
    return (
<div className="row">
			<div className="col">
			</div>
			<div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
				
					<div className="heading">Featured Articles</div>
					<div className="sub-heading sub-heading-featured">Series: From Employee to Contractor</div>			
					
					<div className="row article-row " >
						<div className="col-md-2 col-sm-12 article-image " ><a href="https://dev.to/canosielabs/getting-your-next-contract-756" target="_blank"><img src="https://canosielabs.com/img/articles/contracting/c4.png" className="article-img "  /></a> </div>
						<div className="col-md-9 col-sm-12">
							<div className="article-main-div">
								<p><a  className="article-title" href="https://dev.to/canosielabs/getting-your-next-contract-756" target="_blank">#4: Getting Your Next Contract</a></p>
								<p  className="article-description">
									In this <a href="https://dev.to/canosielabs/getting-your-next-contract-756" target="_blank">article</a>, I'll cover the mindset new contractors may want to consider as they enter the world of contracting.									
								</p>
								<p>
									By: <a href="https://twitter.com/JennrmillerDev" target="_blank">@jennrmiller</a>
								</p>
							</div>
						</div>
						<div className="col-sm-0"></div>
					</div>

					<div className="row article-row" >
						<div className="col-md-2 col-sm-12 article-image" ><a href="https://dev.to/jennrmillerdev/why-you-should-incorporate-as-a-contractor-2ia5" target="_blank"><img src="https://canosielabs.com/img/articles/contracting/c3.jpg" className="article-img" /></a> </div>
						<div className="col-md-9 col-sm-12">
							<div className="article-main-div" >
								<p><a  className="article-title" href="https://dev.to/jennrmillerdev/why-you-should-incorporate-as-a-contractor-2ia5" target="_blank">#3: Why you Should Incorporate</a></p>
								<p  className="article-description">
									In this <a href="https://dev.to/jennrmillerdev/why-you-should-incorporate-as-a-contractor-2ia5" target="_blank">article</a>, I'll talk about the 'developer' aspects on whether you should incorporate your contracting business or not. 								
								</p>
								<p>
									By: <a href="https://twitter.com/JennrmillerDev" target="_blank">@jennrmiller</a>
								</p>
							</div>
						</div>
						<div className="col"></div>
					</div>	
					<div className="row article-row article-row-middle">
							<div className="col-md-2 col-sm-12 article-image" ><a href="https://dev.to/jennrmillerdev/types-of-contractors-in-software-development-45cc" target="_blank"><img src="https://canosielabs.com/img/articles/contracting/c2.png" className="article-img" /></a> </div>
                            <div className="col-md-9 col-sm-12">
								<div className="article-main-div">
									<p><a  className="article-title" href="https://dev.to/jennrmillerdev/types-of-contractors-in-software-development-45cc" target="_blank">#2: Types of Contractors in Software Development</a></p>
									<p  className="article-description">
										In this second  <a href="https://dev.to/jennrmillerdev/types-of-contractors-in-software-development-45cc" target="_blank">article</a> of my "Introduction to IT contracting" series, I will focus on the typical types of contracts you may encounter, how you get paid, and the pros/cons of each type.									
									</p>
									<p>
										By: <a href="https://twitter.com/JennrmillerDev" target="_blank">@jennrmiller</a>
									</p>
								</div>
							</div>
							<div className="col"></div>
					</div>
					<div className="row article-row" >
							<div className="col-md-2 col-sm-12 article-image" ><a href="https://dev.to/jennrmillerdev/things-to-think-about-before-becoming-contractor-1dpi" target="_blank"><img src="https://canosielabs.com/img/articles/contracting/c1.png" className="article-img" /></a> </div>
                            <div className="col-md-9 col-sm-12">
								<div className="article-main-div">
									<p><a  className="article-title" href="https://dev.to/jennrmillerdev/things-to-think-about-before-becoming-contractor-1dpi" target="_blank">#1: Becoming a Contractor in Software Development</a></p>
									<p  className="article-description">
										In this <a href="https://dev.to/jennrmillerdev/things-to-think-about-before-becoming-contractor-1dpi" target="_blank">article</a>, I'll explain some of the fundamental differences between being an employee versus a contractor that are not usually discussed when developers consider contracting.									
									</p>
									<p>
										By: <a href="https://twitter.com/JennrmillerDev" target="_blank">@jennrmiller</a>
									</p>
								</div>
							</div>
							<div className="col"></div>
					</div>
			

				
		
				<br /><br />
				<div className="social-media-links">
                <a href="https://twitter.com/canosielabs" target="_blank"><img src="https://canosielabs.com/img/twitter.png"
                className="social-media-link" /></a>
            <a href="https://www.instagram.com/canosielabs/" target="_blank"><img src="https://canosielabs.com/img/instagram.png"
                className="social-media-link" /></a>
				</div>
			</div>
			<div className="col">
			</div>
		</div>
    )
};

export default ArticlesPage;