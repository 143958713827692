import React from 'react';
import InterviewQuestion from '../../../component/interview-questions/InterviewQuestion';
import {questions, summary, introduction} from '../../../component/interview-questions/JavaHashQuestions';

const InterviewQuestionsHashcode = () => {
    return (<InterviewQuestion
        title="General Java Interview Questions: Equals and hashcode"
        introduction={introduction}
        summary={summary}
        questions={questions} 
        featureImage="https://www.canosielabs.com/blog/wp-content/uploads/2020/12/springboot-interview-feature-1024x485.png"
        />);
};

export default InterviewQuestionsHashcode;