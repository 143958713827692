import React from 'react';
import InterviewQuestion from '../../../component/interview-questions/InterviewQuestion';
import {questions, summary, introduction} from '../../../component/interview-questions/SpringQuestions';

const InterviewQuestionsSpring = () => {
    return (<InterviewQuestion
        title="Spring and Springboot Interview Questions"
        introduction={introduction}
        summary={summary}
        questions={questions} 
        featureImage="https://www.canosielabs.com/blog/wp-content/uploads/2020/12/springboot-interview-feature-1024x485.png"
        />);
};

export default InterviewQuestionsSpring;