import React, { Component } from 'react';

import Question from './Question';
import './InterviewQuestion.scss';
import ShowSourceService from '../../service/ShowSourceService';
class InterviewQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {showAllSources: ShowSourceService.getSourceDisplay()};

        this.handleCodeOptionChange = this.handleCodeOptionChange.bind(this);
    }

    handleCodeOptionChange() {
        const newValue = !ShowSourceService.getSourceDisplay();
        ShowSourceService.setSourceDiplay(newValue);
        this.setState({showAllSources: newValue});
    }

    
    render() {
        const {questions, introduction, title, featureImage} = this.props;

        const questionsList = questions.map((q, index) =>  <Question showCode={this.state.showAllSources} questionNumber={index+1} question={q} key={index} />);
        return (
            <React.Fragment>
                 <div className="row">
                    <div className="col"></div>
                    <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
                        <img className="featured-image rounded mx-auto d-block" src={featureImage} />
                    </div>
              
                    <div className="col"></div>
                </div>
                <div className="row first-row">
                    <div className="col"></div>
                    <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6 blog-title">
                        <h1>{title}</h1>
                    </div>
              
                    <div className="col"></div>
                </div>
                <div className="row hide-sources-row">
                    <div className="col"></div>
                    <div className="col-sm-10  col-md-10 col-lg-10 col-xl-6">
                    {introduction}
                    </div>
              
                    <div className="col"></div>
                </div>
          
                {questionsList}
            </React.Fragment>
        )
    }
}

export default InterviewQuestion;