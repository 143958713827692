import React from "react"
import PrismCode from './PrismCode';
const summary = "";
const introduction = "Over the years, I’ve created, been asked, and acquired a number of interview questions." + 
"So I’ve decided to publish them because I think others will find them useful. Though the questions are a combination of" + 
"my own creations and what I’ve seen, all the answers are of my own(unless noted). Please let me know if there are typos are mistakes by making a comment here: ";

const questions = [
    {
        question: <span>When you are writing equals() method, what other method(s) do you need to override?</span>,
        answer: (<span>You should also implement hashcode().  You should also consider compareTo()</span>)
    },
    {
        question: <span>What is a hashcode?</span>,
        answer: (<span> hash code is an integer value that is associated with each object in Java. Its main purpose is to \
            facilitate hashing in hash tables, which are used by data structures like HashMap.</span>)
    },
    {
        question: <span>What is a hashcode() and equals() contract?</span>,
        answer: (<span>If two objects are equal to each other based on equals() method, then the hash code must be the same, but if the hash code 
            is the same, then equals() can return false</span>)
    },
    {
        question: <span>If you have two objects with the same hashcode, why can equals still return false?</span>,
        answer: (<span>If two objects have the same hashcode then they are NOT necessarily equal. The hash is to facilitie hashing of the object and not 
            object equality.  Hash functions do not need to perfectly identify an object instance, but for effective hasing to work, collisions, the situation  that
            occur when two objects produce the same hash code should be minimized. 
        </span>)
    },
    {
        question: <span>Can I only implement equals() but not hashCode()?</span>,
        answer: (<span>Yes.  There is nothing in the Java language that prevents this.  This is because Object implements both methods.  
            However, if you override equals() without hashCode() there is a highly liky that you will be breaking the equals-hashcode contract.
        </span>)
    },
    {
        question: <span>Can I only implement equals() but not hashCode()?</span>,
        answer: (<span>Yes.  There is nothing in the Java language or complier that prevents this.  This is because Object implements both methods.  
            However, if you override equals() without hashCode() there is a highly liky that you will be breaking the equals-hashcode contract.
        </span>)
    },
    {
        question: <span>If a class has hashcode implemnted such that it always returns the same value, what happens when you put it into a map and then try to get it?
                    <PrismCode code={'@Override'  +'\n' + 
                'public int hashCode() { '  + '\n'  +
                '  return 1;'  + '\n' +
                '}'
        } language="java" />
        
        </span>,
        answer: (<span>A Map or Set will use the hashcode to place the object in the right bucket.  If the hashcode function for this class always returns 
            the same answer, then it the Map will always put it into the same bucket causing a O(n) lookup cost but it will still work since 
            Maps can handle hash collisions because it will use equals() to return the correct result.  Collisoins are internally stored as a list.
              If the hash function is good, we can 
            get close to O(1) lookups.  
        </span>)
    },
    {
        question: <span>Will the above work if equals returns the same result for different objects?</span>,
        answer: (<span>No, this will return unpredictable results.  While a Map can handle collisions caused by a poor hash function,
            it cannot handle a equals method that returns equality on two objects not actually equal.
        </span>)
    },
    {
        question: <span>What happens if hashcode() can return different results for the same object but equals() has the correct behaviour?
           <PrismCode code={'@Override'  +'\n' + 
                'public int hashCode() { '  + '\n'  +
                '  return (int) (Math.random()*10);'  + '\n' +
                '}'+ '\n' + '\n' + '\n' +
                '// try to get object from the map'+ '\n' +
                'm.get(key);'
        } language="java" />

        </span>,
        answer: (<span>It is important to know hashcode() is used to find the bucket first, then equals() is used to find the exact object.  
            If hashcode returns a different result 
            each time, we may not find the object if we call map.get(key) since we'll look for the object in the wrong bucket.  This is regardless 
            if equals() is implemented properly or not.
        </span>)
    },
    {
        question: <span>Should you use ID in the equals() for a Hibernate object?</span>,
        answer: (<span>It depends.  The danger of using the primary key is that it is not assigned to a instance until it is saved to the database.
            The use of a natural key based on business data is the best way.
        </span>)
    },
    {
        question: <span>
            What is the output of the following map and how many buckets will be consumed:
            <PrismCode code={`class Person {    
    private String name;
    
    public Person(String name) { // constructor
           this.name = name;
    }
 
    //hashCode() method not overridden
 
    //equals() method not overridden       
}

// in some program
HashMap<Person, String> map=new Map<Car, String>();
map.put(new Person("Joe"), "p1");
map.put(new Person("Sara"), "p2");
map.put(new Person("Joe"), "p3");

System.out.println("Map size:" + hm.size());  // 1 
System.out.println(map.get(new Person("Joe")) == null);  // 2
`} language="java" />
        </span>,
        answer: (<span><ol>
            <li>Since neither hashcode() or equals() is overriden, the Object#hashcode() and Object#equals() will be used.  
            This will treat all 3 Person keys as different and the map size will be 3.</li>
            <li>The map will use Object's implementation of hashcode() and equals() thus will treat this Person instance as a
                different key than what is stored.  The response will be null, and the "true" will be printed. </li>
            </ol>
        </span>)
    },
];

export {questions, summary, introduction};