import React from 'react';
import InterviewQuestion from '../../../component/interview-questions/InterviewQuestion';
import {questions, summary, introduction} from '../../../component/interview-questions/JsReactExercises';

const InterviewReactExercises = () => {
    return (<InterviewQuestion
        title="React Interview Exercises"
        introduction={introduction}
        summary={summary}
        questions={questions} 
        featureImage="https://canosielabs.com/img/articles/js/react-exercises-js.png"
        />);
};

export default InterviewReactExercises;