import React from 'react';

const createQuestionDefinition = (Component) => {

    return ({definition, ...props}) => (
        <div className="row ">
            <div className="col">
                <Component {...props} />
            </div>
        </div>
    );
};

export default createQuestionDefinition;